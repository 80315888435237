import { OrderByDirection } from '@securecore-new-application/securecore-datacore/lib/types';
import { Dropdown as AntdDropdown } from 'antd';
import { ReactElement, useCallback, useState } from 'react';

import { DropdownContent, DropdownItem } from './Dropdown.styles';

export interface DropdownItemType {
  label: ReactElement | string;
  value?: string;
  key: string;
  action?: () => void;
  direction?: OrderByDirection;
}

export type DropdownPosition = 'left' | 'right';
export type DropdownItemsLayout = 'vertical' | 'horizontal';

interface DropdownProps<T> {
  children: ReactElement | string;
  dropdownItems?: T[];
  onSelect?: (item: T) => void;
  position?: DropdownPosition;
  itemsLayout?: DropdownItemsLayout;
}

interface DropdownMenuProps<T> {
  items: T[];
  onSelect: (item: T) => void;
  hideMenu: () => void;
  position?: DropdownPosition;
  itemsLayout?: DropdownItemsLayout;
}

function DropdownMenu<T extends DropdownItemType>({
  items,
  onSelect,
  hideMenu,
  position = 'right',
  itemsLayout = 'vertical',
}: DropdownMenuProps<T>) {
  const handleSelect = useCallback(
    (item: T) => {
      if (item.action) {
        item.action();
      } else if (onSelect) {
        onSelect(item);
      }
      hideMenu();
    },
    [hideMenu, onSelect]
  );

  return (
    <DropdownContent position={position} itemsLayout={itemsLayout}>
      {items.map((item) => (
        <DropdownItem key={item.key} onClick={() => handleSelect(item)}>
          {item.label}
        </DropdownItem>
      ))}
    </DropdownContent>
  );
}

export function Dropdown<T extends DropdownItemType>({
  children,
  dropdownItems = [],
  onSelect,
  position,
  itemsLayout = 'vertical',
}: DropdownProps<T>) {
  const [isVisible, setIsVisible] = useState(false);
  const hideMenu = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleSelect = useCallback(
    (item: T) => {
      if (onSelect) {
        onSelect(item);
      }
      setIsVisible(false);
    },
    [onSelect]
  );

  const handleSetIsVisible = useCallback((flag: boolean) => {
    setIsVisible(flag);
  }, []);

  return (
    <AntdDropdown
      overlay={
        <DropdownMenu
          itemsLayout={itemsLayout}
          hideMenu={hideMenu}
          items={dropdownItems}
          onSelect={handleSelect}
          position={position}
        />
      }
      trigger={['click']}
      open={isVisible}
      onOpenChange={handleSetIsVisible}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    >
      {children}
    </AntdDropdown>
  );
}
