import { InboxOutlined } from '@ant-design/icons';
import { AttachmentTypes } from '@securecore-new-application/securecore-datacore/lib/types';
import { UploadFile, UploadProps } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';

import { mimeTypes } from '../UploadModal/mime';
import { StyledUploader } from './Uploader.styles';

export const getUploadFileType = (temporaryFile?: UploadFile) =>
  (Object.keys(mimeTypes) as unknown as Array<keyof typeof mimeTypes>).find((group) =>
    mimeTypes[group].includes(temporaryFile?.type as string)
  );

type UploaderProps = UploadProps & { acceptType?: AttachmentTypes; setBlob: (arg0: Blob) => void };
export type TemporaryFile = { file?: UploadFile; type?: AttachmentTypes; base64?: string };

export const Uploader = ({ acceptType, children, onChange, setBlob, ...props }: UploaderProps) => {
  const handleBeforeUpload = (file: RcFile) => {
    setBlob(file);

    return false;
  };

  const handleOnChange = async (info: UploadChangeParam) => onChange?.(info);

  const fileType = {
    [AttachmentTypes.Image]: 'image',
    [AttachmentTypes.Video]: 'video',
    [AttachmentTypes.Doc]: 'document',
    default: 'file',
  }[acceptType || 'default'];

  return (
    <StyledUploader
      {...props}
      multiple={false}
      maxCount={1}
      beforeUpload={handleBeforeUpload}
      showUploadList={false}
      onChange={handleOnChange}
    >
      {children || (
        <>
          <div className="ant-upload-drag-icon">
            <InboxOutlined />
          </div>
          <p className="ant-upload-text">Upload your {fileType} from Computer</p>
        </>
      )}
    </StyledUploader>
  );
};
