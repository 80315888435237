export enum ONBOARDING_ENTITIES {
  DASHBOARD = 'dashboard',
  COMPANY = 'company',
  TEAM = 'team',
  CONTENT_ITEM = 'contentItem',
  HISTORY = 'history',
  PARENT_ENTITIES = 'parentEntities',
  ATTACHMENTS = 'attachments',
}

export interface OnboardingStep {
  title: string;
  content: string;
  image: string;
  imageMobile: string;
}

export type StepStatus = 'current' | 'previous' | 'next';
