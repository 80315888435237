import { AttachmentTypes } from '@securecore-new-application/securecore-datacore/lib/types';
import { ReactElement, useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  AddContentItemParams,
  Content,
  ContentType,
  SaveContentItemParams,
  useCurrentUser,
  useGetScreenStatus,
} from '../../../hooks';
import { SCREEN_STATUS } from '../../../types';
import { UploadModal } from '../../UploadModal';
import { SubmitUploadModal } from '../../UploadModal/types';
import { InfoButton } from '../Button';
import { ContentItemEntityName } from '../ContentItemDrawer';
import { Dropdown } from '../Dropdown';
import Icon, { IconTypes } from '../Icon/Icon';
import { TextSection } from '../TextSection';
import { theme } from '../themes/default';
import { ContentItemWrapper } from './ContentItem.styles';
import { ContentItemEntity } from './ContentItemEntity';

export interface DropdownItem {
  key: string;
  label: ReactElement;
  action?: () => void;
}

export interface OpenUploadParams {
  type: AttachmentTypes;
  id?: string;
  childInd?: number;
  additionalItem?: boolean;
}

export const ContentItemIconProps = {
  width: 18,
  height: 18,
  fillColor: theme.mainBlack,
};

interface Props {
  title?: Content[];
  handleSetTitle?: (arg0: SaveContentItemParams) => void;
  contentItem: ContentType[];
  handleAddContentItem: (arg0: AddContentItemParams) => void;
  handleSetContent: (arg0: SaveContentItemParams) => void;
  handleDeleteContentItem: (id: string) => void;
  handleMoveContentItem: (dragIndex: number, hoverIndex: number) => void;
  readonly?: boolean;
  entityName?: ContentItemEntityName;
}

interface ParamsInterface {
  companyId: string;
}

export function ContentItem({
  title,
  handleSetTitle,
  contentItem,
  handleAddContentItem,
  handleSetContent,
  handleDeleteContentItem,
  handleMoveContentItem,
  readonly,
  entityName,
}: Props) {
  const params = useParams<keyof ParamsInterface>() as ParamsInterface;
  const currentUser = useCurrentUser();
  const companyId = currentUser?.companyId || +params.companyId;
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;
  const [uploadType, setUploadType] = useState<AttachmentTypes | null>(null);
  const [itemId, setItemId] = useState<string | undefined>(undefined);
  const [childIndex, setChildIndex] = useState<number | undefined>(undefined);
  const [isAdditionalItem, setAdditionalItem] = useState<boolean>(false);
  const openUpload = useCallback(({ type, id, childInd, additionalItem = false }: OpenUploadParams) => {
    setUploadType(type);
    setItemId(id);
    setAdditionalItem(additionalItem);
    setChildIndex(childInd);
  }, []);
  const closeUpload = useCallback(() => {
    setChildIndex(undefined);
    setUploadType(null);
    setItemId(undefined);
    setAdditionalItem(false);
  }, []);
  const getDropdownItems = useCallback(
    (id?: string): DropdownItem[] => [
      {
        key: 'text',
        label: (
          <>
            <Icon icon={IconTypes.TextAlignLeft} {...ContentItemIconProps} />
            <div>Text</div>
          </>
        ),
        action: () => handleAddContentItem({ id: uuid() }),
      },
      {
        key: 'image',
        label: (
          <>
            <Icon icon={IconTypes.Image} {...ContentItemIconProps} />
            <div>Image</div>
          </>
        ),
        action: () => openUpload({ type: AttachmentTypes.Image, id }),
      },
      {
        key: 'video',
        label: (
          <>
            <Icon icon={IconTypes.Camera} {...ContentItemIconProps} />
            <div>Video</div>
          </>
        ),
        action: () => openUpload({ type: AttachmentTypes.Video, id }),
      },
    ],
    [handleAddContentItem, openUpload]
  );
  const saveMedia = useCallback(
    (submit: SubmitUploadModal) => {
      const type = uploadType === AttachmentTypes.Image ? 'image' : 'video';

      if (isAdditionalItem) {
        handleSetContent({ id: itemId, type, content: submit.url, childIndex });
      } else {
        handleAddContentItem({ id: itemId, type, content: submit.url });
      }
    },
    [childIndex, handleAddContentItem, handleSetContent, isAdditionalItem, itemId, uploadType]
  );

  const renderAddButton = useCallback(
    (id?: string) => (
      <Dropdown dropdownItems={getDropdownItems(id)} itemsLayout="horizontal">
        <div style={{ cursor: 'pointer' }}>
          <InfoButton leftIcon={<Icon icon={IconTypes.LPlus} fillColor={theme.darkGrey} />} dashed />
        </div>
      </Dropdown>
    ),
    [getDropdownItems]
  );

  return (
    <>
      <ContentItemWrapper isSmallScreen={isSmallScreen}>
        {!readonly && title && (
          <TextSection
            entityName={entityName}
            initialValue={title}
            createContent={handleSetTitle}
            showToolbar={false}
          />
        )}

        <DndProvider backend={HTML5Backend}>
          {contentItem.map((item, i) => (
            <ContentItemEntity
              key={i}
              id={item.id}
              contentItem={item}
              index={i}
              handleSetContent={handleSetContent}
              handleDeleteContentItem={handleDeleteContentItem}
              handleMoveContentItem={handleMoveContentItem}
              readonly={readonly}
              entityName={entityName}
              openUpload={openUpload}
            />
          ))}
        </DndProvider>

        {!readonly && renderAddButton()}
      </ContentItemWrapper>
      {uploadType && (
        <UploadModal
          visible={Boolean(uploadType)}
          companyId={companyId}
          acceptType={uploadType}
          onCancel={closeUpload}
          onSubmit={saveMedia}
        />
      )}
    </>
  );
}
