/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { BaseEditor, Editor, Element as SlateElement, Transforms } from 'slate';

import { IconTypes } from '../Icon/Icon';
import { TextSectionType } from './TextSection';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const markButtonsConfig = [
  {
    format: 'bold',
    icon: IconTypes.Bold,
  },
  {
    format: 'italic',
    icon: IconTypes.Italic,
  },
  {
    format: 'underline',
    icon: IconTypes.Underline,
  },
];

export const blockButtonsConfig = [
  {
    format: 'heading-one',
    icon: IconTypes.HeadingOne,
  },
  {
    format: 'heading-two',
    icon: IconTypes.HeadingTwo,
  },
  {
    format: 'numbered-list',
    icon: IconTypes.NumericList,
  },
  {
    format: 'bulleted-list',
    icon: IconTypes.DotsList,
  },
  {
    format: 'left',
    icon: IconTypes.TextAlignLeft,
  },
  {
    format: 'center',
    icon: IconTypes.TextAlignCenter,
  },
  {
    format: 'right',
    icon: IconTypes.TextAlignRight,
  },
  {
    format: 'justify',
    icon: IconTypes.TextAlignJustify,
  },
];

export const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align };

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export const isBlockActive = (editor: BaseEditor, format: string, blockType = 'type') => {
  const { selection } = editor;

  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node: any) => node[blockType] === format,
    })
  );

  return !!match;
};

export const isMarkActive = (editor: BaseEditor, format: string) => {
  const marks: any = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};

export const toggleBlock = (editor: BaseEditor, format: string) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (node: any) => LIST_TYPES.includes(node.type) && !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: any;

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      // eslint-disable-next-line no-nested-ternary
      type: isActive ? TextSectionType.paragraph : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };

    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: BaseEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
