import { Modal as AntdModal, ModalProps } from 'antd';
import styled from 'styled-components';

import { theme } from '../themes/default';

export const ActionsSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  .ant-btn {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const StyledModal = styled(AntdModal)<ModalProps>`
  .ant-modal-title {
    font-size: 20px;
  }
  .ant-modal-close {
    top: 5px;
    right: 5px;
  }
  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    & > svg > path {
      transition: fill 0.3s ease;
    }
    &:hover {
      & > svg > path {
        fill: ${theme.mainBackground}!important;
      }
    }
  }
`;
