import { RefObject, useEffect } from 'react';

export const useComponentVisible = (ref: RefObject<HTMLDivElement>, onClose: () => void) => {
  const rootElement = document.getElementById('root');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(<Node>event.target)) {
        onClose();
      }
    };

    rootElement?.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      rootElement?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, ref, rootElement]);
};

export default useComponentVisible;
