import { Slider } from 'antd';
import { darken, rgba } from 'polished';
import EasyCrop from 'react-easy-crop';
import styled from 'styled-components';

import { theme } from '../../ui/themes/default';

export const CropperContainer = styled.div`
  width: 100%;
  position: relative;
  height: 370px;
  opacity: 0.8;
  background-color: ${rgba(theme.mediumGrey, 0.8)};

  & .reactEasyCrop_CropAreaGrid::after,
  & .reactEasyCrop_CropAreaGrid::before {
    border-color: ${rgba(theme.primary, 0.5)};
  }
`;

export const Cropper = styled(EasyCrop).attrs({
  style: { cropAreaStyle: { border: `2px solid ${theme.primary}` } },
})``;

export const Controls = styled.div`
  position: relative;
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const SliderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0 10px;
  width: 100%;
  max-width: 268px;
  align-self: center;
`;
export const StyledSlider = styled(Slider).attrs({
  trackStyle: { backgroundColor: theme.primary },
  handleStyle: { backgroundColor: theme.primary, borderWidth: 0 },
  color: 'green',
})`
  display: flex;
  flex: 1 1 268px;

  &.ant-slider:hover .ant-slider-rail {
    background-color: ${darken(0.05, theme.lightOrange)};
  }

  & .ant-slider-rail {
    background-color: ${theme.lightOrange};
  }
  & .ant-slider-handle:focus {
    box-shadow: 0 0 0 5px ${rgba(theme.primary, 0.12)};
  }
`;
