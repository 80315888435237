import { getHtmlToContentItemSerializer, serializeContentItem } from '@securecore-new-application/securecore-parser';
import {
  Children,
  Content,
  ContentChildrenType,
  ContentItemType,
  ContentMediaType,
  ContentType,
} from '@securecore-new-application/securecore-parser/lib/types';
import update from 'immutability-helper';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { TextSectionType } from '../components/ui/TextSection';

export {
  type Children,
  type Content,
  type ContentChildrenType,
  type ContentItemType,
  type ContentMediaType,
  type ContentType,
};

export interface AddContentItemParams {
  id?: string;
  type?: ContentChildrenType;
  content?: string;
}

export interface SaveContentItemParams {
  id?: string;
  childIndex?: number;
  type?: ContentChildrenType;
  content: Content[] | string;
}

interface ContentItemHandlers {
  addContentItem: (arg0: AddContentItemParams) => void;
  deleteContentItem: (id: string) => void;
  moveContentItem: (dragIndex: number, hoverIndex: number) => void;
  saveContentItem: (arg0: SaveContentItemParams) => void;
  parseContentItem: (arg0: string) => void;
  cleanUp: () => ContentType[];
  serializeToHtml: (currentContent: ContentType[]) => string;
}

const deserializer = getHtmlToContentItemSerializer(DOMParser);

export const initialTextContentItem: ContentType[] = [
  {
    id: uuid(),
    type: 'text',
    content: [
      {
        type: TextSectionType.paragraph,
        children: [{ text: '' }],
      },
    ],
  },
];

interface HookArgs {
  initialContent?: ContentType[];
  stringContent?: string;
}

export function useContentItem({
  initialContent = initialTextContentItem,
  stringContent,
}: HookArgs): [ContentType[], ContentItemHandlers] {
  const [contentItem, setContentItem] = useState<ContentType[]>([]);

  const parseContentItem = useCallback((stringifiedItem: string) => {
    const parsedData = deserializer(stringifiedItem);

    setContentItem(parsedData as ContentType[]);
  }, []);

  useEffect(() => {
    if (stringContent) {
      parseContentItem(stringContent);
    } else if (initialContent) {
      setContentItem(initialContent);
    }
  }, [initialContent, parseContentItem, stringContent]);

  const generateNewContent = useCallback((type: ContentChildrenType, content: string) => {
    switch (type) {
      case 'video':
      case 'image':
        return {
          id: uuid(),
          type: 'media' as ContentItemType,
          content: [{ type: 'media', children: [{ type, url: content }] }],
        };
      default:
        return {
          id: uuid(),
          type: 'text' as ContentItemType,
          content: [{ type: TextSectionType.paragraph, children: [{ text: content }] }],
        };
    }
  }, []);

  const addContentItem = useCallback(
    ({ id, type = 'text', content = '' }: AddContentItemParams) => {
      if (id === undefined) {
        setContentItem([...contentItem, generateNewContent(type, content)]);

        return;
      }

      const lastItem = contentItem.length;
      const contentToUpdate = [...contentItem];

      if (type === 'text') {
        contentToUpdate.splice(lastItem, 0, {
          id: uuid(),
          type: 'text',
          content: [{ type: TextSectionType.paragraph, children: [{ text: content }] }],
        });
      }

      if (['image', 'video'].includes(type)) {
        contentToUpdate.splice(lastItem, 0, {
          id: uuid(),
          type: 'media',
          content: [{ type: 'media', children: [{ type, url: content }] }],
        });
      }

      setContentItem(contentToUpdate);
    },
    [contentItem, generateNewContent]
  );

  const deleteContentItem = useCallback(
    (id: string) => {
      setContentItem(contentItem.filter((item) => item.id !== id));
    },
    [contentItem]
  );

  const saveContentItem = useCallback(
    ({ content, id, childIndex, type = 'text' }: SaveContentItemParams) => {
      let contentToUpdate;

      if (Array.isArray(content) && type === 'text') {
        contentToUpdate = contentItem.map((item) => (item.id === id ? { ...item, content } : item));
        setContentItem(contentToUpdate);

        return;
      }

      const currentItem = contentItem.find((item) => item.id === id);
      const currentItemIndex = contentItem.findIndex((item) => item.id === id);

      contentToUpdate = [...contentItem];

      if (childIndex && currentItem) {
        const childrenToUpdate = [...currentItem.content[0].children];

        childrenToUpdate.splice(childIndex, 0, { type, url: content as string });
        contentToUpdate[currentItemIndex].content[0].children = childrenToUpdate;
      } else {
        contentToUpdate[currentItemIndex].content[0].children.push({
          type,
          url: content as string,
        });
      }

      setContentItem(contentToUpdate);
    },
    [contentItem]
  );

  const moveContentItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setContentItem((prevContentItem) =>
      update(prevContentItem, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevContentItem[dragIndex]],
        ],
      })
    );
  }, []);

  // deepFilter is a helper which removes empty content items from the contentItem array. Returns a filtered ContentItem array.
  const deepFilter = (currentContent: ContentType[]) => {
    const filtered: ContentType[] = currentContent.reduce((acc, ci) => {
      if (ci.type === 'media') {
        acc.push(ci);

        return acc;
      }

      if (ci.type === 'text') {
        acc.push(ci);

        return acc;
      }

      const items = ci.content.reduce((cacc, cci) => {
        const children = cci.children.reduce((chacc, chci) => {
          // We need to filter out paragraphs, titles and lists
          // TODO: find more proper solution for different types of content
          if (chci.text || chci.type === 'list-item') {
            chacc.push(chci);
          }

          return chacc;
        }, [] as Children[]);

        if (children.length) {
          cacc.push({
            ...cci,
            children,
          });
        }

        return cacc;
      }, [] as Content[]);

      if (items.length) {
        acc.push({
          ...ci,
          content: items,
        });
      }

      return acc;
    }, [] as ContentType[]);

    return filtered;
  };

  const cleanUp = useCallback(() => deepFilter(contentItem), [contentItem]);

  const serializeToHtml = useCallback((currentContent: ContentType[]) => serializeContentItem(currentContent), []);

  return [
    contentItem,
    {
      addContentItem,
      deleteContentItem,
      moveContentItem,
      saveContentItem,
      parseContentItem,
      cleanUp,
      serializeToHtml,
    },
  ];
}
