import styled from 'styled-components';

import { theme } from '../ui/themes/default';

export const MobileBannerWrapper = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  padding: 13px 16px;
  border: 1px solid #e6e6f0;
`;
export const MobileBannerLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const MobileBannerIcon = styled.div`
  display: flex;
  min-width: 40px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #e6e6f0;
`;
export const MobileBannerImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: contain;
`;
export const MobileBannerCopy = styled.div`
  margin-left: 8px;
`;
export const MovileBannerTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.mainBlack};
`;
export const MobileBannerText = styled.div`
  margin-top: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.darkGrey};
`;
export const MobileBannerRight = styled.div`
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MobileBannerLink = styled.a`
  color: ${theme.orange};
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;
