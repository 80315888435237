import { Space } from 'antd';
import styled from 'styled-components';

export const Spacer = styled(Space).attrs({ block: true, size: 'small' })`
  align-items: center;
  gap: 1em;
  height: auto;
  width: 100%;

  & :nth-child(1) {
    width: 100%;
  }
`;
