import { Attachment } from '@securecore-new-application/securecore-datacore/lib/types';
import { ChangeEvent, FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { ValidationMessages } from '../../../notification';
import { getErrorMessage, hasError } from '../../../utils/validation';
import { FormItem } from '../../ui/Form';
import { Input } from '../../ui/Input';
import { Spacer } from './UploadForm.styles';

export type FormValues = Omit<Attachment, 'createdAt' | 'updatedAt' | 'id'>;

interface UploadForm {
  control: Control<FormValues, unknown>;
  errors: FieldErrors<FormValues>;
  filename?: string;
  setMediaUrl?: (url: string) => void;
  urlEnabled?: boolean;
}

export const UploadForm: FC<UploadForm> = ({ control, errors, filename = '', setMediaUrl, urlEnabled }) => {
  const extensionName = filename.slice((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);

  return (
    <>
      {filename && (
        <FormItem
          label="File Name"
          validateStatus={hasError({ field: 'name', errors })}
          help={getErrorMessage({ field: 'name', errors })}
          required
        >
          <Spacer>
            <Controller
              rules={{ required: ValidationMessages.REQUIRED_FIELD }}
              control={control}
              name="name"
              render={({ field }) => <Input {...field} />}
            />
            {extensionName && <div>.{extensionName}</div>}
          </Spacer>
        </FormItem>
      )}
      {urlEnabled && !filename && (
        <FormItem
          label="or add URL"
          validateStatus={hasError({ field: 'url', errors })}
          help={getErrorMessage({ field: 'url', errors })}
          required
        >
          <Spacer>
            <Controller
              control={control}
              name="url"
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="https://..."
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (setMediaUrl) {
                      setMediaUrl(e.target.value);
                    }
                  }}
                />
              )}
            />
            {extensionName && <div>.{extensionName}</div>}
          </Spacer>
        </FormItem>
      )}
    </>
  );
};
