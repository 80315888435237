import { useCallback } from 'react';

import { ContentMediaType, useGetScreenStatus } from '../../../hooks';
import { SCREEN_STATUS } from '../../../types';
import { GalleryModal, StyledGallery, StyledPlayer } from './Gallery.styles';

export interface GalleryImage {
  original: string;
  thumbnail?: string;
}
export interface GalleryProps {
  items?: GalleryImage[];
  url?: string;
  closeGallery(): void;
  type?: ContentMediaType;
}

export function Gallery({ items = [], closeGallery, type = 'image', url }: GalleryProps) {
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;
  const renderContent = useCallback(() => {
    if (type === 'video') {
      return <StyledPlayer url={url} controls />;
    }

    return (
      <StyledGallery
        items={items}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        showBullets={false}
        indexSeparator=" of "
        showIndex
      />
    );
  }, [items, type, url]);

  return (
    <GalleryModal open onCancel={closeGallery} width={type === 'video' ? 900 : '100%'} isSmallScreen={isSmallScreen}>
      {renderContent()}
    </GalleryModal>
  );
}
