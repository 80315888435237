import { Drawer } from 'antd';
import styled from 'styled-components';

import { theme } from '../themes/default';

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  padding: 10px;
`;

export const ContentDrawer = styled(Drawer)<{ isSmallScreen?: boolean }>`
  .ant-drawer-content-wrapper {
    ${({ isSmallScreen }) => (isSmallScreen ? 'width: 100% !important;' : '')};
  }
  & .ant-drawer-inline {
    top: 48px;
  }

  & .ant-drawer-body {
    padding: 32px;
    display: flex;
    flex-direction: column;
  }

  & .ant-drawer-content {
    background: ${theme.lightGrey};
  }

  & .ant-drawer-header {
    display: none;
  }

  & .ant-drawer-content-wrapper {
    box-shadow: none;
  }
`;

export const ContentItemHeader = styled.div<{ isSmallScreen?: boolean }>`
  display: flex;
  align-items: ${({ isSmallScreen }) => (isSmallScreen ? 'left' : 'center')};
  justify-content: space-between;
  text-transform: capitalize;
`;

export const ContentItemTitle = styled.div<{ isSmallScreen?: boolean }>`
  text-transform: capitalize;
  display: flex;
  align-items: ${({ isSmallScreen }) => (isSmallScreen ? 'left' : 'center')};
  flex-direction: ${({ isSmallScreen }) => (isSmallScreen ? 'column' : 'row')};
  gap: ${({ isSmallScreen }) => (isSmallScreen ? '8px' : '24px')};
`;
