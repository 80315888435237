import { useChangeUserPassword } from '@securecore-new-application/securecore-datacore';
import { Form, notification, Popover } from 'antd';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { passwordRegex } from '../../constants';
import { useCurrentUser, useGetScreenStatus } from '../../hooks';
import { NotificationMessages, ValidationMessages } from '../../notification';
import { SCREEN_STATUS } from '../../types';
import { getError, getErrorMessage, hasError } from '../../utils/validation';
import { ActionsSection } from '../MembersPageTooltip/MembersPageTooltip.styles';
import { Button } from '../ui/Button';
import { FormItem } from '../ui/Form';
import { Password } from '../ui/Input';
import { Modal } from '../ui/Modal';
import { CurrentPasswordWrapper, ForgotLinkWrapper, StyledLink } from './ChangePasswordModal.styles';

interface Props {
  visible: boolean;
  userId?: number;
  onCloseModal: () => void;
  userName?: string;
}

enum FormFields {
  currentPassword = 'currentPassword',
  password = 'password',
  confirmPassword = 'confirmPassword',
}

interface ChangePasswordForm {
  [FormFields.currentPassword]: string;
  [FormFields.password]: string;
  [FormFields.confirmPassword]: string;
}

export function ChangePasswordModal({ visible, onCloseModal, userId, userName }: Props) {
  const {
    control,
    formState: { errors, touchedFields },
    handleSubmit,
    trigger,
    getValues,
    reset,
  } = useForm<ChangePasswordForm>();
  const [changeUserPassword, { loading }] = useChangeUserPassword();
  const currentScreen = useGetScreenStatus();
  const currentUser = useCurrentUser();
  const isButtonDisabled = loading || !!Object.keys(errors).length;

  const handleCloseModal = useCallback(() => {
    reset();
    onCloseModal();
  }, [onCloseModal, reset]);

  const changePassword = useCallback(
    async (formData: ChangePasswordForm) => {
      const changePasswordInput = { currentPassword: formData.currentPassword, password: formData.password, userId };

      await changeUserPassword({
        variables: { data: changePasswordInput },
      });
      navigator.clipboard.writeText(formData.password);
      notification.success({ message: NotificationMessages.PASSWORD_CHANGED_COPIED });
      handleCloseModal();
    },
    [changeUserPassword, handleCloseModal, userId]
  );

  const forgotClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!currentUser?.profile?.email) {
        e.preventDefault();
        notification.warning({ message: NotificationMessages.PASSWORD_FORGOT_ALERT });

        return false;
      }

      return true;
    },
    [currentUser?.profile]
  );

  return (
    <Modal
      open={visible}
      title="Change password"
      onCancel={handleCloseModal}
      destroyOnClose
      onOk={handleSubmit(changePassword)}
      footer={
        <ActionsSection>
          <Button variant="secondary" fullWidth key="button" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button fullWidth key="submit" onClick={handleSubmit(changePassword)} disabled={isButtonDisabled}>
            Save
          </Button>
        </ActionsSection>
      }
    >
      <div>
        <Form onFinish={handleSubmit(changePassword)} style={{ marginTop: 24 }}>
          {userName && <FormItem label="User Name">{userName}</FormItem>}
          {!userId && (
            <CurrentPasswordWrapper>
              <FormItem
                label="Current Password"
                required
                validateStatus={hasError({ field: FormFields.currentPassword, errors })}
                help={getErrorMessage({ field: FormFields.currentPassword, errors })}
              >
                <Controller
                  control={control}
                  name={FormFields.currentPassword}
                  rules={{
                    required: ValidationMessages.REQUIRED_FIELD,
                  }}
                  render={({ field }) => <Password {...field} />}
                />
              </FormItem>
              <ForgotLinkWrapper>
                <StyledLink href="/auth/forgot" onClick={forgotClick}>
                  Forgot password?
                </StyledLink>
              </ForgotLinkWrapper>
            </CurrentPasswordWrapper>
          )}
          <FormItem
            label="New Password"
            required
            validateStatus={hasError({ field: FormFields.password, errors })}
            help={getErrorMessage({ field: FormFields.password, errors })}
          >
            <Controller
              control={control}
              name={FormFields.password}
              rules={{
                required: ValidationMessages.REQUIRED_FIELD,
                pattern: {
                  value: passwordRegex,
                  message: 'Invalid password!',
                },
              }}
              render={({ field }) => (
                <Popover
                  content={
                    <div style={{ maxWidth: 300 }}>
                      Password should contain minimum 8 characters and at least 1 upper case, 1 lower case and 1 number.
                    </div>
                  }
                  placement={currentScreen === SCREEN_STATUS.extraSmall ? 'top' : 'right'}
                  open={getError({ field: 'password', errors })?.type === 'pattern'}
                >
                  <Password {...field} />
                </Popover>
              )}
            />
          </FormItem>
          <FormItem
            label="Confirm Password"
            required
            validateStatus={hasError({ field: FormFields.confirmPassword, errors })}
            help={getErrorMessage({ field: FormFields.confirmPassword, errors })}
          >
            <Controller
              control={control}
              name={FormFields.confirmPassword}
              rules={{
                required: ValidationMessages.REQUIRED_FIELD,
                validate: (val: string) => {
                  const fieldToCheck = getValues(FormFields.password);

                  if (fieldToCheck.length && fieldToCheck !== val) {
                    return 'Your passwords do not match.';
                  }
                  if (touchedFields[FormFields.password]) trigger(FormFields.password);
                },
              }}
              render={({ field }) => <Password {...field} />}
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}
