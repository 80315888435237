import { ModalProps } from 'antd';

import Icon, { IconTypes } from '../Icon/Icon';
import { StyledModal } from './Modal.styles';

export function Modal({ children, open, closable, ...props }: ModalProps) {
  return (
    <StyledModal
      centered
      footer={null}
      open={open}
      {...props}
      closable={closable}
      maskClosable={false}
      closeIcon={<Icon icon={IconTypes.Cross} width={22} height={22} />}
    >
      {children}
    </StyledModal>
  );
}
