import SimpleCrypto, { PlainData } from 'simple-crypto-js';

import { STORAGE_TOKEN_NS } from '../../constants/keys';

const crypto = new SimpleCrypto(STORAGE_TOKEN_NS);

export class LocalStorage {
  private static getInternalKey(key: string): string {
    return encodeURIComponent(`${STORAGE_TOKEN_NS}${key}`);
  }

  public static setItem(key: string, value: PlainData): void {
    const internalKey: string = LocalStorage.getInternalKey(key);

    localStorage.setItem(`${internalKey}`, `${crypto.encrypt(value)}`);
  }

  public static getItem(key: string): PlainData | null {
    const internalKey: string = LocalStorage.getInternalKey(key);
    const value = localStorage.getItem(`${internalKey}`);

    if (!value) {
      return null;
    }

    return crypto.decrypt(value);
  }

  public static removeItem(key: string): void {
    const internalKey: string = LocalStorage.getInternalKey(key);

    localStorage.removeItem(`${internalKey}`);
  }
}
