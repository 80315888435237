export const entityAddNotificationMessage = (entityTitle: string) => `New ${entityTitle} has been added.`;
export const entityRemoveNotificationMessage = (entityTitle: string) => `${entityTitle} has been deleted.`;
export const entityRemoveConfirmNotificationMessage = (entityTitle: string) =>
  `Are you sure to delete this ${entityTitle}?`;
export const entityUpdateNotificationMessage = (entityTitle: string) => `The ${entityTitle} was successfully updated.`;

export enum ValidationMessages {
  REQUIRED_FIELD = 'This field is required!',
}
export enum NotificationMessages {
  ALARM_ADDED = 'New alarm has been added.',
  ALARM_REMOVED = 'Alarm has been removed.',
  ALARM_UPDATED = 'The alarm was successfully updated.',
  ATTACHMENT_REMOVED = 'File has been removed.',
  BUILDING_DELETED = 'Building has been deleted.',
  BUILDINGS_ADDED = 'Buildings has been added.',
  BUILDINGS_UPDATED = 'Buildings has been updated.',
  CONTACT_ADDED = 'Contact has been added.',
  CONTACT_REMOVED = 'Contact has been removed.',
  CONTACT_UPDATED = 'Contact has been updated.',
  COMMAND_CENTER_ADDED = 'New command center has been added.',
  COMMAND_CENTER_REMOVED = 'Command Center has been removed.',
  COMMAND_CENTER_UPDATED = 'The command center was successfully updated.',
  COMPANY_CREATED = 'You are successfully created the company.',
  COMPANY_INFO_UPDATED = 'Company info was updated.',
  COPIED_CLIPBOARD = 'Copied to clipboard.',
  EMAIL_SENT = 'An email with instructions was sent. Please, check your email inbox. If you have not received the email within 30 minutes, please check your spam/junk mail folder for the email.',
  ENTITY_RESTORED = 'Entity was successfully restored.',
  ENTITY_REVERTED = 'Entity was successfully reverted.',
  INVITATIONS_SENT = 'Invitations has been sent.',
  INVITATION_SENT = 'Invitation has been sent.',
  PASSWORD_CHANGED_COPIED = 'Password has been changed and copied to clipboard.',
  PASSWORD_CHANGED = 'You password was successfully changed.',
  PASSWORD_FORGOT_ALERT = 'Please fill in your email address or contact your Admin',
  PROCEDURE_UPDATED = 'Procedure was successfully updated.',
  PROCEDURE_DELETED = 'Procedure was successfully deleted.',
  PROPERTY_UPDATED = 'Property has been updated.',
  PROPERTY_DELETED = 'Property has been deleted.',
  PROPERTIES_ADDED = 'Properties has been added.',
  PROFILE_UPDATED = 'Profile was successfully updated.',
  TEAM_MEMBER_REMOVED = 'Team member has been deleted.',
  TENANT_SPACE_ADDED = 'Tenant Space has been added.',
  TENANT_SPACE_UPDATED = 'Tenant Space has been updated.',
  TENANT_SPACE_DELETED = 'Tenant Space has been deleted.',
  USER_CREATED_COPIED = 'User created successfully, credentials copied to your clipboard.',
  EVACUATION_ADDED = 'New evacuation has been added.',
  EVACUATION_REMOVED = 'Evacuation has been removed.',
  EVACUATION_UPDATED = 'Evacuation was successfully updated.',
  EVACUATION_DELETE_CONFIRM = 'Are you sure to delete this evacuation?',
  FAQ_REMOVED = 'FAQ has been removed.',
  FAQ_ADDED = 'New FAQ has been added.',
  FAQ_DELETE_CONFIRM = 'Are you sure to delete this FAQ?',
  FAQ_UPDATED = 'FAQ was successfully updated.',
  MEDIA_DELETE_CONFIRM = 'All images/videos will be deleted from the section. Are you sure?',
  CONTENT_DELETE_CONFIRM = 'All content will be deleted from the section. Are you sure?',
  SHUTOFF_DELETE_CONFIRM = 'Are you sure to delete this shut-off?',
  SHUTOFF_CREATED = 'Shut-off has been successfully created.',
  SHUTOFF_DELETED = 'Shut-off has been deleted.',
  SHUTOFF_UPDATED = 'Shut-off has been updated.',
  FILE_UPLOAD_SUCCESS = 'File has been uploaded.',
  MEDIA_LIMIT = 'Maximum amount of media items has been reached for this section. Please create new section to add more.',
  CONTACT_UPDATE_ERROR = 'Unable to update contact.',
  TEAM_MEMBER_ACCESS_UPDATED = 'Team member access has been successfully updated.',
  PHONE_WARNING = 'Primary phone number is missing. Please provide a phone number to ensure the contact can be added to the Call List.',
}
