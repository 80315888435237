import { StyledText, StyledTitle, TextProps, TitleProps } from './Typography.styles';

export type TitleVariant = 'heading1' | 'heading2' | 'heading3';
export type TextVariant = 'bodySmall' | 'bodyRegular' | 'bodyBold' | 'bodySemibold' | 'bodyMedium' | 'description';
export type TextColor = 'primary' | 'secondary';

export function Title({ variant, children, color, ...rest }: TitleProps) {
  return (
    <StyledTitle variant={variant} color={color} {...rest}>
      {children}
    </StyledTitle>
  );
}

export function Text({ variant, upperCase, color, bold, children, ...props }: TextProps) {
  return (
    <StyledText variant={variant} upperCase={upperCase} color={color} bold={bold} {...props}>
      {children}
    </StyledText>
  );
}
