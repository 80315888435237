import { FC, useEffect, useRef } from 'react';

import { InfiniteScrollProps } from './InfiniteScrollProps';

export const InfiniteScroll: FC<InfiniteScrollProps> = ({ hasMore, loading, fetchMoreItems }) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    observerRef.current?.disconnect();
    observerRef.current = new IntersectionObserver((entries) => {
      if (hasMore && entries[0].isIntersecting && !loading) {
        fetchMoreItems?.();
      }
    });
    if (targetDivRef.current) {
      observerRef.current.observe(targetDivRef.current);
    }

    return () => observerRef.current?.disconnect();
  }, [fetchMoreItems, hasMore, loading]);

  return <div ref={targetDivRef}>&nbsp;</div>;
};
