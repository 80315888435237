import { AttachmentTypes } from '@securecore-new-application/securecore-datacore/lib/types';

export const mimeTypes = {
  [AttachmentTypes.Image]: [
    'image/aces',
    'image/apng',
    'image/avci',
    'image/avcs',
    'image/avif',
    'image/bmp',
    'image/cgm',
    'image/dicom-rle',
    'image/emf',
    'image/fits',
    'image/g3fax',
    'image/gif',
    'image/heic',
    'image/heic-sequence',
    'image/heif',
    'image/heif-sequence',
    'image/hej2k',
    'image/hsj2',
    'image/ief',
    'image/jls',
    'image/jp2',
    'image/jpeg',
    'image/jph',
    'image/jphc',
    'image/jpm',
    'image/jpx',
    'image/jxr',
    'image/jxra',
    'image/jxrs',
    'image/jxs',
    'image/jxsc',
    'image/jxsi',
    'image/jxss',
    'image/ktx',
    'image/ktx2',
    'image/png',
    'image/sgi',
    'image/svg+xml',
    'image/t38',
    'image/tiff',
    'image/tiff-fx',
    'image/webp',
    'image/wmf',
  ],
  [AttachmentTypes.Video]: [
    'video/3gpp',
    'video/3gpp2',
    'video/h261',
    'video/h263',
    'video/h264',
    'video/iso.segment',
    'video/jpeg',
    'video/jpm',
    'video/mj2',
    'video/mp2t',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/quicktime',
    'video/webm',
  ],
  [AttachmentTypes.Doc]: [
    'application/msword',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-excel',
    'application/gzip',
    'application/zip',
    'application/rtf',
    'text/plain',
  ],
};
