import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

import { theme } from '../themes/default';

export type ButtonSizeType = 'large' | 'medium' | 'small' | 'extraSmall';

interface CustomButtonProps {
  buttonSize: ButtonSizeType;
}

interface CustomIconButtonProps {
  buttonSize: ButtonSizeType;
  round?: boolean;
  outlined?: boolean;
}

const ButtonSizes = {
  large: '56px',
  medium: '48px',
  small: '40px',
  extraSmall: '40px',
};

const CustomButton = styled(AntdButton)<CustomButtonProps>`
  height: ${({ buttonSize }) => ButtonSizes[buttonSize]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 2px solid transparent;
  font-size: 15px;

  > span {
    display: flex;
  }
`;

export const PrimaryButton = styled(CustomButton)`
  &:hover {
    background: ${theme.mainBackground};
    border: 2px solid transparent;
  }
  &:active {
    background: ${theme.mainBackgroundActive};
    border: 2px solid transparent;
  }
  &:focus {
    border: 2px solid ${theme.borderFocus};
  }
`;

export const SecondaryButton = styled(CustomButton)`
  ${({ disabled }) =>
    disabled && {
      background: `${theme.mediumGrey} !important`,
      opacity: 0.5,
      color: `${theme.secondary} !important`,
    }}
  &:hover {
    background: ${theme.secondaryBackgroundHover};
    color: ${theme.secondary};
    border: 2px solid transparent;
  }
  &:active {
    background: ${theme.secondaryBackgroundActive};
    color: ${theme.secondary};
    border: 2px solid transparent;
  }
  &:focus {
    border: 2px solid ${theme.borderFocus};
    color: ${theme.secondary};
  }
`;

export const OutlineButton = styled(CustomButton)`
  background: transparent;
  border: 1px solid ${theme.primary};
  color: ${theme.primary};

  ${({ disabled }) =>
    disabled && {
      background: 'transparent !important',
      opacity: 0.5,
      color: `${theme.primary} !important`,
    }}
  &:hover {
    background: ${theme.primary};
    color: ${theme.white};

    & > svg > path {
      fill: ${theme.white} !important;
    }

    & > span > svg > path {
      fill: ${theme.white} !important;
    }
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), ${theme.primary};
    color: ${theme.white};
    border: none;
  }
  &:focus {
    border: 1px solid ${theme.primary};
    filter: drop-shadow(0px 0px 9px ${theme.borderFocus});
  }
`;

export const AdditionalButton = styled(CustomButton)`
  background: transparent;
  border: 1px solid ${theme.grey};
  color: ${theme.mainBlack};
  line-height: 70px;
  height: 70px;
  border-radius: 16px;
  justify-content: flex-start;

  ${({ disabled }) =>
    disabled && {
      background: 'transparent !important',
      opacity: 0.5,
      color: `${theme.primary} !important`,
    }}
  &:hover {
    background: ${theme.secondaryBackgroundHover};
    color: ${theme.mainBlack};
    border-color: ${theme.grey};
  }
  &:active {
    background: ${theme.secondaryBackgroundActive};
    color: ${theme.mainBlack};
    border: none;
  }
  &:focus {
    border: 1px solid ${theme.grey};
    color: ${theme.mainBlack};
    /* filter: drop-shadow(0px 0px 9px ${theme.borderFocus}); */
  }

  svg {
    right: 30px;
    position: absolute;
  }
`;

export const AddButton = styled(PrimaryButton)`
  font-size: 16px;
  > span {
    margin-right: 6px;
    &:after {
      content: '|';
      padding-left: 6px;
      font-weight: 100;
      opacity: 0.5;
    }
  }
`;
export const EmergencyButton = styled(PrimaryButton)`
  padding-left: 10px;
  padding-right: 10px;
  > svg {
    transition: ${theme.transitionTransform};
  }
  &.ant-dropdown-open {
    background: transparent;
    border: 1px solid ${theme.primary};
    color: ${theme.primary};
    & > svg > path {
      fill: ${theme.primary} !important;
    }
    & > svg:last-child {
      transform: rotate(180deg);
    }
  }
`;

export const TextButtonStyled = styled(AntdButton)<{ fullWidth?: boolean }>`
  height: 24px;
  color: ${theme.primary};
  padding: 2.5px 0;
  background: ${theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'initial')};
  box-shadow: none;

  ${({ disabled }) =>
    disabled && {
      background: `${theme.white} !important`,
      opacity: 0.5,
      color: `${theme.primary} !important`,
    }}
  &:hover {
    background: ${theme.white};
    color: ${theme.primary};
    border: none;
  }
  &:active {
    background: ${theme.white};
    border: none;
  }
  &:focus {
    border: 1px solid ${theme.borderFocus};
    box-shadow: 0 0 0 1px ${theme.borderFocus};
    color: ${theme.primary};
    background: ${theme.white};
  }
`;

export const InfoButtonStyled = styled(AntdButton)<{ dashed?: boolean }>`
  height: 24px;
  color: ${theme.secondary};
  padding: 4px;
  background: ${theme.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: ${({ dashed }) => (dashed ? `1px dashed ${theme.gridItemBorder}` : '2px solid transparent')};

  ${({ disabled }) =>
    disabled && {
      background: `${theme.mediumGrey} !important`,
      opacity: 0.5,
      color: `${theme.secondary} !important`,
    }}
  &:hover {
    color: ${theme.secondary};
    border: 2px solid transparent;
  }
  &:active {
    color: ${theme.secondary};
    border: 2px solid transparent;
  }
  &:focus {
    border: 2px solid ${theme.borderFocus};
    color: ${theme.secondary};
  }
`;

const IconButtonSizes = {
  large: '56px',
  medium: '32px',
  small: '24px',
  extraSmall: '12px',
};

export const IconButtonStyled = styled(AntdButton)<CustomIconButtonProps>`
  background: ${theme.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0;
  width: ${({ buttonSize }) => IconButtonSizes[buttonSize]};
  height: ${({ buttonSize }) => IconButtonSizes[buttonSize]};
  flex: ${({ buttonSize }) => `0 0 ${IconButtonSizes[buttonSize]}`};
  ${({ round }) => (round ? 'border-radius: 50%' : '')};
  ${({ outlined }) => (outlined ? `background: transparent; border: 1px solid ${theme.grey}` : '')};

  ${({ disabled }) =>
    disabled && {
      background: `${theme.mediumGrey} !important`,
      opacity: 0.5,
      color: `${theme.secondary} !important`,
    }}

  &:hover {
    background: ${theme.mediumGrey};
    color: ${theme.secondary};
  }
  &:active {
    color: ${theme.secondary};
    background: ${theme.mediumGrey};
    border: none;
  }
  &:focus {
    background: ${theme.mediumGrey};
    border: 2px solid ${theme.borderFocus};
    ${({ round }) => (round ? 'border-radius: 50%' : '8px')};
    color: ${theme.secondary};
  }
`;

export const ButtonLabel = styled.span<{ buttonSize?: ButtonSizeType }>`
  margin: ${({ buttonSize }) => (buttonSize === 'extraSmall' ? '0 2px' : '0 10px')};
`;
