import { OrderByDirection } from '@securecore-new-application/securecore-datacore/lib/types';
import { InputRef, Space } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { ColumnType, FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import React, { ReactElement, useCallback, useRef, useState } from 'react';

import { RefetchFunction } from '../../Attachment/types';
import { InfiniteScroll } from '../../InfiniteScrollList';
import { SortDropdown, SortEntityName } from '../../SortDropdown';
import { Button } from '../Button';
import { DropdownItemType } from '../Dropdown';
import Icon, { IconTypes } from '../Icon/Icon';
import { Input } from '../Input';
import { ListEntityName, ListToggle, ListToggleLayout } from '../ListToggle';
import { SearchHighlight } from '../SearchHighlight';
import { Title } from '../Typography';
import {
  DataCardsWrapper,
  DataTableActions,
  DataTableContent,
  DataTableHeader,
  DataTableWrapper,
  StyledGenericTable,
  SwitchLayoutWrapper,
} from './DataTable.styles';

export interface SortingOption {
  label: string;
  value: unknown;
  direction: OrderByDirection;
  key: string;
}

interface DataTableProps<T> extends TableProps<T> {
  data: T[];
  tableTitle: string | ReactElement;
  columns: ColumnsType<T>;
  sortable?: boolean;
  sortingOptions?: SortingOption[];
  onSort?: (item: SortingOption) => void;
  actions?: ReactElement[] | ReactElement;
  switchLayout?: boolean;
  onLoadMore?: () => void;
  exportable?: boolean;
  onExport?: () => void;
  renderCard?: (item: T) => void;
  needsLoadMore?: boolean;
  sortEntityName?: SortEntityName;
  entityName?: ListEntityName;
}

export function DataTable<T>({
  actions,
  data,
  columns,
  loading,
  tableTitle,
  switchLayout,
  sortable,
  exportable,
  onExport,
  sortingOptions,
  needsLoadMore,
  sortEntityName,
  entityName,
  onSort,
  onLoadMore,
  renderCard,
  ...rest
}: DataTableProps<T>) {
  const [listLayout, setListLayout] = useState<ListToggleLayout>('list');
  const handleSort = useCallback(
    (item: DropdownItemType) => {
      if (onSort) {
        onSort(item as SortingOption);
      }
    },
    [onSort]
  );

  const hasMore = Boolean(data.length) && Boolean(needsLoadMore);

  const renderContent = useCallback(() => {
    return listLayout === 'list' ? (
      <DataTableWrapper>
        <StyledGenericTable columns={columns} dataSource={data} pagination={false} loading={loading} {...rest} />
        <InfiniteScroll hasMore={hasMore} loading={Boolean(loading)} fetchMoreItems={onLoadMore} />
      </DataTableWrapper>
    ) : (
      <>
        <DataCardsWrapper>
          <>{data.map((item) => renderCard?.(item))}</>
        </DataCardsWrapper>
        <InfiniteScroll hasMore={hasMore} loading={Boolean(loading)} fetchMoreItems={onLoadMore} />
      </>
    );
  }, [listLayout, columns, data, loading, rest, hasMore, onLoadMore, renderCard]);

  return (
    <>
      <DataTableHeader>
        <Title variant="heading2">{tableTitle}</Title>
        <DataTableActions>
          {switchLayout && (
            <SwitchLayoutWrapper>
              <ListToggle
                layout={listLayout as ListToggleLayout}
                listEntityName={entityName}
                setListLayout={setListLayout}
              />
            </SwitchLayoutWrapper>
          )}
          {exportable && (
            <Button variant="outline" size="small" onClick={onExport}>
              Export
            </Button>
          )}
          {sortable && onSort && (
            <SortDropdown
              sortingOptions={sortingOptions as DropdownItemType[]}
              onChange={handleSort}
              sortEntityName={sortEntityName}
            />
          )}

          {actions}
        </DataTableActions>
      </DataTableHeader>
      <DataTableContent isTableLayout={listLayout === 'list'}>{renderContent()}</DataTableContent>
    </>
  );
}

interface GetColumnSearchProps<T> {
  columnIndex: string;
  filter: Record<string, string>;
  setFilter: (arg0: Record<string, string>) => void;
  render?: (value: unknown, record: T, index: number) => React.ReactNode;
}

export function useGetColumnSearch<T>({
  columnIndex,
  filter,
  setFilter,
  render,
}: GetColumnSearchProps<T>): ColumnType<T> {
  type DataIndex = string;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<T>();
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    setFilter({
      [dataIndex]: selectedKeys[0],
    });
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex as T);
  };

  const handleReset = (confirm: () => void, clearFilters: RefetchFunction | undefined) => {
    setFilter({});
    clearFilters?.();
    confirm();
    setSearchedColumn(undefined);
  };

  const getFilterDropdown = (
    dataIndex: DataIndex | string,
    { setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps
  ) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex as string}`}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          variant="primary"
          buttonSize="small"
          onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          icon={<Icon icon={IconTypes.Search} />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          variant="outline"
          onClick={() => handleReset(confirm, clearFilters)}
          buttonSize="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          variant="secondary"
          buttonSize="small"
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
      </Space>
    </div>
  );

  const filterIcon = (dataIndex: DataIndex | string) => (
    <Icon icon={IconTypes.Search} fillColor={filter[dataIndex as string] ? '#1890ff' : undefined} />
  );

  return {
    filterIcon: filterIcon(columnIndex),
    filterDropdown: (filterProps: FilterDropdownProps) => getFilterDropdown(columnIndex, filterProps),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (value, record, index) => {
      const renderedEl = render?.(value, record, index);
      const isCurrentColumn = Object.keys(filter).includes(columnIndex) && searchedColumn === columnIndex;

      return isCurrentColumn ? <SearchHighlight search={searchText}>{renderedEl}</SearchHighlight> : renderedEl;
    },
  };
}
