import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

interface LoggerInitConfig {
  useSentry: boolean;
  debug?: boolean;
}

export class ErrorLogger {
  static useSentry = false;

  static debug: boolean;

  static init({ useSentry, debug = true }: LoggerInitConfig): void {
    ErrorLogger.useSentry = useSentry;
    ErrorLogger.debug = debug;
  }

  public static logError(error: unknown, info?: Record<string, unknown>): void {
    if (ErrorLogger.debug) {
      if (info) {
        // eslint-disable-next-line no-console
        console.log(info);
      }
      // eslint-disable-next-line no-console
      console.error(error);
    }

    if (ErrorLogger.useSentry) {
      Sentry.captureException(error instanceof Error ? error : new Error((error as Record<string, string>).message));
    }
  }

  public static log(message?: unknown, ...optionalParams: unknown[]): void {
    if (ErrorLogger.debug) {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  }
}

export const initErrorLogging = () => {
  const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENV, REACT_APP_SENTRY_ENABLED } = process.env;
  const sentryEnabled = REACT_APP_SENTRY_ENABLED && parseInt(REACT_APP_SENTRY_ENABLED, 10) === 1;

  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: REACT_APP_SENTRY_ENV,
      enabled: sentryEnabled as boolean,
      tracesSampleRate: 1.0,
    });
  }

  ErrorLogger.init({ useSentry: !!REACT_APP_SENTRY_DSN });
};
