import { useState } from 'react';

import { Button } from '../ui/Button';
import Icon, { IconTypes } from '../ui/Icon/Icon';
import { theme } from '../ui/themes/default';
import { OnboardingModal } from './OnboardingModal';
import { ONBOARDING_ENTITIES } from './types';

interface OnboardingProps {
  entity: ONBOARDING_ENTITIES;
}

export function Onboarding({ entity }: OnboardingProps) {
  const [showOnboarding, setShowOnboarding] = useState(false);

  const revealOnboarding = () => {
    setShowOnboarding(true);
  };
  const hideOnboarding = () => {
    setShowOnboarding(false);
  };

  return (
    <>
      <Button
        onClick={revealOnboarding}
        variant="secondary"
        leftIcon={<Icon icon={IconTypes.Questionmark} width={16} height={16} fillColor={theme.primary} />}
      />
      <OnboardingModal entity={entity} hideOnboarding={hideOnboarding} forceShow={showOnboarding} />
    </>
  );
}
