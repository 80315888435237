import { Grid } from 'antd';

import { SCREEN_STATUS } from '../types';

const { useBreakpoint } = Grid;

export const useGetScreenStatus = (): SCREEN_STATUS => {
  const screens = useBreakpoint();

  if (!screens.md) return SCREEN_STATUS.extraSmall;
  if (!screens.lg) return SCREEN_STATUS.small;
  if (!screens.xl) return SCREEN_STATUS.medium;
  if (!screens.xxl) return SCREEN_STATUS.large;

  return SCREEN_STATUS.large;
};
