import ImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';

import { Modal } from '../Modal';

export const StyledGallery = styled(ImageGallery)``;

export const StyledPlayer = styled(ReactPlayer)`
  max-width: 100%;
  max-height: 100%;
  width: 1100px;
  height: 620px;
  margin: 0 auto;
`;

export const GalleryModal = styled(Modal)<{ isVideo?: boolean; isSmallScreen: boolean }>`
  position: initial;
  max-width: 100%;

  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    position: initial;
    height: 100%;
  }

  .ant-modal-body {
    height: 100%;
  }

  .image-gallery-index {
    top: auto;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  .image-gallery-slide .image-gallery-image {
    width: ${(isSmallScreen) => (isSmallScreen ? '100%' : 'auto')};
    border-radius: 16px;
  }
`;
