import attachmentsImage1 from '../../assets/images/onboarding/attachments/attachments-1.jpg';
import attachmentsImage2 from '../../assets/images/onboarding/attachments/attachments-2.jpg';
import attachmentsImage3 from '../../assets/images/onboarding/attachments/attachments-3.jpg';
import attachmentsImage4 from '../../assets/images/onboarding/attachments/attachments-4.jpg';
import attachmentsMobile1 from '../../assets/images/onboarding/attachments/attachmentsMobile-1.jpg';
import attachmentsMobile2 from '../../assets/images/onboarding/attachments/attachmentsMobile-2.jpg';
import attachmentsMobile3 from '../../assets/images/onboarding/attachments/attachmentsMobile-3.jpg';
import attachmentsMobile4 from '../../assets/images/onboarding/attachments/attachmentsMobile-4.jpg';
import companyImage1 from '../../assets/images/onboarding/company/company-1.jpg';
import companyImage2 from '../../assets/images/onboarding/company/company-2.jpg';
import companyImage3 from '../../assets/images/onboarding/company/company-3.jpg';
import companyMobile1 from '../../assets/images/onboarding/company/companyMobile-1.jpg';
import companyMobile2 from '../../assets/images/onboarding/company/companyMobile-2.jpg';
import companyMobile3 from '../../assets/images/onboarding/company/companyMobile-3.jpg';
import contentItemImage1 from '../../assets/images/onboarding/contentItem/contentItem-1.jpg';
import contentItemImage2 from '../../assets/images/onboarding/contentItem/contentItem-2.jpg';
import contentItemMobile1 from '../../assets/images/onboarding/contentItem/contentItemMobile-1.jpg';
import contentItemMobile2 from '../../assets/images/onboarding/contentItem/contentItemMobile-2.jpg';
import lastActivity from '../../assets/images/onboarding/dashboard/last-activity.jpg';
import lastActivityMobile from '../../assets/images/onboarding/dashboard/last-activity-mobile.jpg';
import lastVisits from '../../assets/images/onboarding/dashboard/last-visits.jpg';
import lastVisitsMobile from '../../assets/images/onboarding/dashboard/last-visits-mobile.jpg';
import welcome from '../../assets/images/onboarding/dashboard/logo.jpg';
import welcomeMobile from '../../assets/images/onboarding/dashboard/logo-mobile.jpg';
import navigation from '../../assets/images/onboarding/dashboard/navigation.jpg';
import navigationMobile from '../../assets/images/onboarding/dashboard/navigation-mobile.jpg';
import profile from '../../assets/images/onboarding/dashboard/profile.jpg';
import profileMobile from '../../assets/images/onboarding/dashboard/profile-mobile.jpg';
import search from '../../assets/images/onboarding/dashboard/search.jpg';
import searchMobile from '../../assets/images/onboarding/dashboard/search-mobile.jpg';
import historyImage1 from '../../assets/images/onboarding/history/history-1.jpg';
import historyImage2 from '../../assets/images/onboarding/history/history-2.jpg';
import historyImage3 from '../../assets/images/onboarding/history/history-3.jpg';
import historyMobile1 from '../../assets/images/onboarding/history/historyMobile-1.jpg';
import historyMobile2 from '../../assets/images/onboarding/history/historyMobile-2.jpg';
import historyMobile3 from '../../assets/images/onboarding/history/historyMobile-3.jpg';
import parentEntitiesImage1 from '../../assets/images/onboarding/parentEntities/parentEntities-1.jpg';
import parentEntitiesImage2 from '../../assets/images/onboarding/parentEntities/parentEntities-2.jpg';
import parentEntitiesImage3 from '../../assets/images/onboarding/parentEntities/parentEntities-3.jpg';
import parentEntitiesMobile1 from '../../assets/images/onboarding/parentEntities/parentEntitiesMobile-1.jpg';
import parentEntitiesMobile2 from '../../assets/images/onboarding/parentEntities/parentEntitiesMobile-2.jpg';
import parentEntitiesMobile3 from '../../assets/images/onboarding/parentEntities/parentEntitiesMobile-3.jpg';
import teamImage1 from '../../assets/images/onboarding/team/team-1.jpg';
import teamImage2 from '../../assets/images/onboarding/team/team-2.jpg';
import teamImage3 from '../../assets/images/onboarding/team/team-3.jpg';
import teamImage4 from '../../assets/images/onboarding/team/team-4.jpg';
import teamMobile1 from '../../assets/images/onboarding/team/teamMobile-1.jpg';
import teamMobile2 from '../../assets/images/onboarding/team/teamMobile-2.jpg';
import teamMobile3 from '../../assets/images/onboarding/team/teamMobile-3.jpg';
import teamMobile4 from '../../assets/images/onboarding/team/teamMobile-4.jpg';
import { ONBOARDING_ENTITIES, OnboardingStep } from './types';

// prettier-ignore
export const STEPS: Record<ONBOARDING_ENTITIES, Record<number, OnboardingStep>> = {
  [ONBOARDING_ENTITIES.DASHBOARD]: {
    1: {
      title: 'Welcome to the new SecureCore!',
      content: 'Welcome to the new SecureCore! Each page will have a guide to show you what\'s new and what you can do.',
      image: welcome,
      imageMobile: welcomeMobile,
    },
    2: {
      title: 'Navigation Bar',
      content: 'Use this menu to move between your company profile, properties, and company-wide procedures. Don\'t forget to take advantage of the SecureCore FAQ any time you have a question.',
      image: navigation,
      imageMobile: navigationMobile,
    },
    3: {
      title: 'User Profile',
      content: 'Change your password and update your information from your user profile.',
      image: profile,
      imageMobile: profileMobile,
    },
    4: {
      title: 'Search Bar',
      content: 'To help you find what you need faster, search here for the name of a team member, utility shut off, vendor, procedure, or any other item within your SecureCore disaster plan.',
      image: search,
      imageMobile: searchMobile,
    },
    5: {
      title: 'Last Visits',
      content: 'Quickly access a recently visited property, building or tenant space without having to go to your company or property page.',
      image: lastVisits,
      imageMobile: lastVisitsMobile,
    },
    6: {
      title: 'Recent Activity',
      content: 'See a summary of your most recent activity or edits in your property to help you keep track of what was recently changed.',
      image: lastActivity,
      imageMobile: lastActivityMobile,
    },
  },
  [ONBOARDING_ENTITIES.COMPANY]: {
    1: {
      title: 'Your Company',
      content: 'Your company profile page shows you the properties you have access to, your company procedures, your company team in SecureCore, and your approved media representative.',
      image: companyImage1,
      imageMobile: companyMobile1,
    },
    2: {
      title: 'Tab Navigation',
      content: 'Click on a tab to show the information available to you within the section.',
      image: companyImage2,
      imageMobile: companyMobile2,
    },
    3: {
      title: 'History',
      content: 'See a history of changes made by clicking on the clock icon. You can also access approved company photos and files by clicking on the folder icon.',
      image: companyImage3,
      imageMobile: companyMobile3,
    },
  },
  [ONBOARDING_ENTITIES.TEAM]: {
    1: {
      title: 'Your Team',
      content: 'We\'ve added this new feature to make it easier to manage who has access to your disaster plan. You can see who has access to properties and your company admin can create and edit user accounts.',
      image: teamImage1,
      imageMobile: teamMobile1,
    },
    2: {
      title: 'Access Levels',
      content: 'Your company team shows all members who have SecureCore user accounts. Your property team shows the members who have access to that property.',
      image: teamImage2,
      imageMobile: teamMobile2,
    },
    3: {
      title: 'Creating a New User',
      content: 'When your company admin creates a user account, an email will be sent to the user to complete their registration.',
      image: teamImage3,
      imageMobile: teamMobile3,
    },
    4: {
      title: 'Changing a Password',
      content: 'Admins have the ability to change or reset user passwords.',
      image: teamImage4,
      imageMobile: teamMobile4,
    },
  },
  [ONBOARDING_ENTITIES.CONTENT_ITEM]: {
    1: {
      title: 'Procedures',
      content: 'Use this section to document what your team should (or should not) do in certain situations. You can add and format text, as well as add new sections before or after each section with the + button, to customize your procedure.',
      image: contentItemImage1,
      imageMobile: contentItemMobile1,
    },
    2: {
      title: 'Adding pictures and videos',
      content: 'Files such as pictures, documents and video clips can be added.',
      image: contentItemImage2,
      imageMobile: contentItemMobile2,
    },
  },
  [ONBOARDING_ENTITIES.HISTORY]: {
    1: {
      title: 'History',
      content: 'See a history of changes made by clicking on the clock icon. Changes may include additions, edits and deleted items.',
      image: historyImage1,
      imageMobile: historyMobile1,
    },
    2: {
      title: 'Restoring Deleted Items',
      content: 'You may restore a deleted item by clicking "Restore".',
      image: historyImage2,
      imageMobile: historyMobile2,
    },
    3: {
      title: 'Reverting Changes',
      content: 'You may revert an edit by clicking "Revert". You have the ability to review the edit that was made before reverting to the previous item.',
      image: historyImage3,
      imageMobile: historyMobile3,
    },
  },
  [ONBOARDING_ENTITIES.PARENT_ENTITIES]: {
    1: {
      title: 'Your Property',
      content: 'Your property page shows you the properties you have access to along with any buildings and tenant spaces. In here you will find property specific information and procedures.',
      image: parentEntitiesImage1,
      imageMobile: parentEntitiesMobile1,
    },
    2: {
      title: 'Tab Navigation',
      content: 'Property specific information is broken out into tabs. To navigate, click on the tab name you wish to view.',
      image: parentEntitiesImage2,
      imageMobile: parentEntitiesMobile2,
    },
    3: {
      title: 'History',
      content: 'See a history of changes made by clicking on the clock icon. Changes may include additions, edits and deleted items.',
      image: parentEntitiesImage3,
      imageMobile: parentEntitiesMobile3,
    },
  },
  [ONBOARDING_ENTITIES.ATTACHMENTS]: {
    1: {
      title: 'Pictures, documents and video clips',
      content: 'See attachments by clickng on the file icon. Files can include pictures, documents and video clips.',
      image: attachmentsImage1,
      imageMobile: attachmentsMobile1,
    },
    2: {
      title: 'Attachment Menu',
      content: 'Attachments can be added by clicking on the file icon. These attachments will be under the property specific section they have been added to. They can be viewed here or under your company.',
      image: attachmentsImage2,
      imageMobile: attachmentsMobile2,
    },
    3: {
      title: 'Attachments View',
      content: 'Attachments can be viewed in list or grid view. The can be sorted by a property specific tab or viewed all together.',
      image: attachmentsImage3,
      imageMobile: attachmentsMobile3,
    },
    4: {
      title: 'Video Player',
      content: 'This new feature allows you to upload and play videos.',
      image: attachmentsImage4,
      imageMobile: attachmentsMobile4,
    },
  },
};
