import styled from 'styled-components';

import videoPlaceholder from '../../../assets/images/video-placeholder.png';
import Icon, { IconTypes } from '../Icon/Icon';
import { theme } from '../themes/default';

export const MediaRow = styled.div<{ isSmallScreen: boolean }>`
  display: grid;
  grid-template-columns: ${({ isSmallScreen }) => (isSmallScreen ? '1fr' : 'repeat(auto-fit, minmax(100px, 1fr))')};

  & + & {
    padding-top: 8px;
  }
`;

export const PlayIcon = styled(Icon).attrs({
  icon: IconTypes.Play,
  width: 48,
  height: 48,
  fillColor: theme.white,
})`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
  transition: ${theme.transitionOpacity};
  pointer-events: none;
`;

export const MediaWrapper = styled.div<{ isSmallScreen: boolean }>`
  position: relative;
  cursor: pointer;

  .ant-dropdown-trigger {
    position: absolute;
    top: 50%;
    right: -15px;
    width: 30px;
    z-index: 100;
    transform: translateY(-50%);
    padding: 3px;
    border-radius: 8px;
    background: ${theme.mediumGrey};
  }

  & + & {
    padding: ${({ isSmallScreen }) => (isSmallScreen ? '10px 0 0 0' : '0 0 0 8px')};
  }

  &:hover ${PlayIcon} {
    opacity: 0.6;
  }
`;

export const ContentItemWrapper = styled.div<{ isSmallScreen?: boolean }>`
  margin: ${({ isSmallScreen }) => (isSmallScreen ? '10px 0' : '24px 16px;')};
`;

export const ContentItemEntityContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0;

  & > div > button:first-child {
    background: ${theme.white};
  }
`;

export const MediaItem = styled.div`
  border-radius: 8px;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 300px;
  }
`;

export const VideoPlaceholder = styled.div`
  appearance: none;
  background-color: #323234;
  background-image: url(${videoPlaceholder});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 211px 106px;
  width: 100%;
  height: 300px;
  border-radius: 8px;
`;

export const ContentItemEntityItem = styled.div<{ readonly?: boolean }>`
  cursor: ${({ readonly }) => (readonly ? 'default' : 'pointer')};
  border-radius: 8px;
  position: relative;
  padding: 2px;
  width: 100%;

  & > button {
    position: absolute;
    top: -16px;
    right: -16px;
    background: white;
    display: none;
  }

  &:hover {
    background: ${({ readonly }) => !readonly && theme.mediumGrey};

    button {
      display: flex;
    }
  }
`;
