import { useGetSignedUrl } from '@securecore-new-application/securecore-datacore';
import { AttachmentTypes } from '@securecore-new-application/securecore-datacore/lib/types';
import { Form, notification } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player/lazy';

import { NotificationMessages } from '../../notification';
import { ErrorLogger } from '../../utils/ErrorLogger';
import { getFileDataUrl } from '../../utils/files';
import { Button } from '../ui/Button';
import { MediaItem } from '../ui/ContentItem/ContentItem.styles';
import { Modal } from '../ui/Modal';
import { ActionsSection } from '../ui/Modal/Modal.styles';
import { Uploader } from '../Uploader';
import { getUploadFileType, TemporaryFile } from '../Uploader/Uploader';
import { ImageEditor } from './ImageEditor';
import { getCroppedImage } from './ImageEditor/getCroppedImage';
import { minZoom } from './ImageEditor/ImageEditor';
import { mimeTypes } from './mime';
import { ImageTransformations, UploadModalProps } from './types';
import { UploadPreview } from './Upload.styles';
import { FormValues, UploadForm } from './UploadForm';

const defaultImageTransformations = {
  crop: { x: 0, y: 0 },
  rotation: 0,
  zoom: minZoom,
  croppedAreaPixels: { width: 0, height: 0, x: 0, y: 0 },
};

export function UploadModal(props: UploadModalProps) {
  const [imageTransformations, setImageTransformations] = useState<ImageTransformations>(defaultImageTransformations);
  const [temporaryFile, setTemporaryFile] = useState<TemporaryFile | null>(null);
  const [mediaUrl, setMediaUrl] = useState<string | null>();
  const [blob, setBlob] = useState<Blob | null>();
  const { visible, onCancel, companyId, propertyId, acceptType, onSubmit } = props;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<FormValues>();

  const [getSignedUrl] = useGetSignedUrl();
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (!visible) {
      reset();
      setTemporaryFile(null);
      setImageTransformations(defaultImageTransformations);
      setMediaUrl(null);
      setBlob(null);
      onCancel();
    }
  }, [onCancel, reset, visible]);

  const updateMediaUrl = (value: string) => {
    setMediaUrl(value);
    setSubmitDisabled(Boolean(!value));
  };

  const accept = ((acceptType && mimeTypes[acceptType]) || []).join(',');

  const uploadFile = useCallback(
    async (formData: FormValues) => {
      try {
        if (mediaUrl) {
          return onSubmit?.({
            name: 'url',
            url: mediaUrl,
            file: undefined,
            fileType: undefined,
          });
        }

        setSubmitDisabled(true);
        const signedUrl = await getSignedUrl({
          variables: {
            data: {
              key: temporaryFile?.file?.name as string,
              propertyId: propertyId as number,
              companyId: companyId as number,
              contentType: temporaryFile?.file?.type as string,
            },
          },
        });
        const uploadUrl = signedUrl.data?.getSignedUrl.uploadUrl as string;
        const fileUrl = signedUrl.data?.getSignedUrl.fileUrl as string;

        let body = blob;

        if (temporaryFile?.type === AttachmentTypes.Image) {
          body = (await getCroppedImage({
            imageSrc: temporaryFile?.base64 as string,
            pixelCrop: imageTransformations.croppedAreaPixels,
            rotation: imageTransformations.rotation,
            filename: temporaryFile?.file?.name as string,
            type: temporaryFile?.file?.type as string,
          })) as RcFile;
        }

        await fetch(uploadUrl, {
          method: 'PUT',
          body,
        });

        onSubmit?.({
          name: formData.name as string,
          url: fileUrl,
          file: temporaryFile?.file,
          fileType: temporaryFile?.type,
        });
      } catch (e) {
        ErrorLogger.logError(e);
        notification.error({ message: 'Unable to upload file' });
      } finally {
        reset();
        setTemporaryFile(null);
        setImageTransformations(defaultImageTransformations);
        notification.success({ message: NotificationMessages.FILE_UPLOAD_SUCCESS });
        onCancel();
      }
    },
    [
      blob,
      companyId,
      getSignedUrl,
      imageTransformations.croppedAreaPixels,
      imageTransformations.rotation,
      mediaUrl,
      onCancel,
      onSubmit,
      propertyId,
      reset,
      temporaryFile?.base64,
      temporaryFile?.file,
      temporaryFile?.type,
    ]
  );

  const handleChange = async (event: UploadChangeParam) => {
    if (!event.file) {
      return;
    }
    const file = event.fileList.find((item) => item.uid === event.file.uid);
    const [basename] = (event.file.name as string).split('.');
    const fileType = getUploadFileType(event.file);
    const isImage = fileType === AttachmentTypes.Image;

    setTemporaryFile({
      file: event.file,
      type: getUploadFileType(event.file),
      ...(isImage && { base64: await getFileDataUrl(file?.originFileObj as File) }),
    });
    setValue('name', basename);
    setSubmitDisabled(false);
  };

  const title = {
    [AttachmentTypes.Image]: 'Upload Image',
    [AttachmentTypes.Video]: 'Upload Video',
    [AttachmentTypes.Doc]: 'Upload File',
  }[temporaryFile?.type || acceptType || AttachmentTypes.Doc];

  return (
    <Modal
      open={visible}
      title={title}
      onCancel={onCancel}
      destroyOnClose
      onOk={handleSubmit(uploadFile)}
      footer={
        <ActionsSection>
          <Button variant="secondary" fullWidth key="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button fullWidth key="submit" onClick={handleSubmit(uploadFile)} disabled={submitDisabled}>
            Upload
          </Button>
        </ActionsSection>
      }
    >
      <Form onFinish={handleSubmit(uploadFile)}>
        {!temporaryFile?.type && !mediaUrl && !blob && (
          <Uploader accept={accept} onChange={handleChange} acceptType={acceptType} setBlob={setBlob} />
        )}
        <UploadPreview>
          {temporaryFile?.base64 && temporaryFile.type === AttachmentTypes.Image && (
            <ImageEditor
              file={temporaryFile.base64}
              transformations={imageTransformations}
              setTransformations={setImageTransformations}
            />
          )}
          {blob && temporaryFile?.type === AttachmentTypes.Video && (
            <ReactPlayer url={URL.createObjectURL(blob)} controls width="100%" height={260} />
          )}
          {mediaUrl && acceptType === AttachmentTypes.Video && <ReactPlayer url={mediaUrl} width="100%" height={260} />}
          {mediaUrl && acceptType === AttachmentTypes.Image && (
            <MediaItem>
              <img src={mediaUrl} alt="" />
            </MediaItem>
          )}
        </UploadPreview>
        <UploadForm
          control={control}
          errors={errors}
          filename={temporaryFile?.file?.name}
          setMediaUrl={updateMediaUrl}
          urlEnabled={Boolean(acceptType)}
        />
      </Form>
    </Modal>
  );
}
