import { useGetMe } from '@securecore-new-application/securecore-datacore';
import { Skeleton } from 'antd';
import React, { FC, useMemo } from 'react';

import { ErrorPage, ErrorPageTypes } from '../../components/Errors';
import { logOut } from '../../utils/logOut';
import { UserContext } from './context';

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const { data, loading, error } = useGetMe({ fetchPolicy: 'no-cache' });
  const value = useMemo(() => {
    return { currentUser: data?.getMe || null };
  }, [data?.getMe]);

  if (error) {
    const gqlError = error.graphQLErrors[0];
    const errorCode = gqlError?.extensions?.code;

    if (errorCode === 'UNAUTHENTICATED') {
      logOut();

      return null;
    }

    return <ErrorPage type={ErrorPageTypes.server} />;
  }

  return <UserContext.Provider value={value}>{loading ? <Skeleton active /> : children}</UserContext.Provider>;
};
