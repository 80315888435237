import { EmergencyResources, TeamRoles } from '@securecore-new-application/securecore-datacore/lib/types';

import { DropdownItemType } from '../components/ui/Dropdown';

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const passwordRegex =
  // eslint-disable-next-line no-control-regex
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const userNameRegexp = /^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
// Should update keys on the sorting task
export const sortDropdownItems: DropdownItemType[] = [
  {
    label: 'Last Updated',
    key: '0',
  },
  {
    label: 'First Updated',
    key: '1',
  },
  {
    label: 'From A to Z',
    key: '2',
  },
  {
    label: 'From Z to A',
    key: '3',
  },
  {
    label: 'Created Date',
    key: '4',
  },
];

export const userRoles = [
  {
    key: TeamRoles.ADMIN,
    label: 'Admin',
  },
  {
    key: TeamRoles.STAFF_MEMBER,
    label: 'Staff Member',
  },
  {
    key: TeamRoles.MANAGER,
    label: 'Manager',
  },
];

export const userTitles = [
  {
    key: 'maintenanceTechnician',
    label: 'Maintenance Technician',
  },
  {
    key: 'maintenanceManager',
    label: 'Maintenance Manager',
  },
  {
    key: 'propertyManager',
    label: 'Property Manager',
  },
  {
    key: 'regionalPropertyManager',
    label: 'Regional Property Manager',
  },
  {
    key: 'regionalMaintenanceManager',
    label: 'Regional Maintenance Manager',
  },
  {
    key: 'corporateLeadership',
    label: 'Corporate Leadership',
  },
  {
    key: 'leasingAgent',
    label: 'Leasing Agent',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const emergencyResourceTitle = {
  [EmergencyResources.VENDOR]: 'Vendor Contact',
  [EmergencyResources.COMMUNITY_RESOURCE]: 'Community Resource',
};

export const COMPANY_PHONE = '866-498-4038';
export const PER_PAGE = 10;
export const debouncedWait = 400;

export const getUserTitle = (keyTitle: string) => {
  return userTitles.find(({ key }) => keyTitle === key)?.label || '';
};

export const PHONE_MASK = '+1 (999) 999-9999';

export const DEFAULT_BUTTON_LOAFING_DELAY = 500;
export const getButtonLoading = (loading: boolean) => loading && { delay: DEFAULT_BUTTON_LOAFING_DELAY };
