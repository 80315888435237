import Table, { TableProps } from 'antd/lib/table';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '../themes/default';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsWrapperRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DataTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-bottom: 16px;
`;

export const DataTableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-left: 28px;
  }
`;

export const DataTableContent = styled.div<{ isTableLayout?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${({ isTableLayout }) =>
    isTableLayout
      ? `
    box-shadow: ${theme.boxShadow};
    border-radius: ${theme.borderRadius};
    padding: 16px;
    background: ${theme.white};
  `
      : ''}
`;

export const ButtonWrapper = styled.div`
  width: 280px;
  margin: 16px auto 32px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const DataTableWrapper = styled.div`
  width: 100%;

  .ant-table-cell.clickable {
    cursor: pointer;
  }

  .ant-table-filter-column {
    justify-content: flex-start;
    gap: 10px;

    .ant-table-column-title {
      flex-grow: 0;
    }
  }
`;

export const SwitchLayoutWrapper = styled.div`
  display: flex;
  width: 56px;
  justify-content: space-between;
`;

export const DataCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 26px;
  gap: 32px;
`;

export const AutoLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    color: ${theme.mainBlack} !important;
  }

  .ant-table-thead {
    tr {
      th {
        font-weight: 600;
        border-bottom: none;
      }

      th::before {
        display: none;
      }
    }
  }

  .ant-table-tbody {
    tr {
      cursor: pointer;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  .hoverable {
    cursor: pointer;
  }
`;

export const TableWrapper = styled.div<{ isSmallScreen?: boolean }>`
  margin-top: 12px;
  margin-bottom: 32px;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    ${({ isSmallScreen }) => (isSmallScreen ? 'padding: 2px; max-width: 50px; font-size: 10px;' : '')};
    span {
      ${({ isSmallScreen }) => (isSmallScreen ? 'font-size: 12px;' : '')};
    }
    .ant-table-filter-trigger {
      ${({ isSmallScreen }) => (isSmallScreen ? 'padding:0; margin:0;' : '')};
    }
  }
  h1 {
    ${({ isSmallScreen }) => (isSmallScreen ? 'font-size: 18px !important;' : '')};
  }
`;

export const DataCard = styled.div`
  width: 343px;
  height: 260px;
  padding: 32px;
  border-radius: ${theme.borderRadius};
  background: ${theme.white};
  box-shadow: ${theme.boxShadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${theme.white};
  transition: ${theme.transitionBG};
  background-size: cover;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.primary};
  }
`;

export const DataCardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(NavLink)`
  color: ${theme.secondary};
`;

export function StyledGenericTable<T>(props: TableProps<T>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <StyledTable {...props} />;
}
