import { UserRoles } from '@securecore-new-application/securecore-datacore/lib/types';
import React, { lazy } from 'react';
import { matchRoutes } from 'react-router-dom';

import { ErrorPage, ErrorPageTypes } from '../components/Errors';
import { IconTypes } from '../components/ui/Icon/Icon';
import { UserProvider } from '../contexts/user';
import AllUsersPage from '../pages/AllUsersPage/AllUsersPage';

// Main App
const DashboardPage = lazy(() => import(/* webpackChunkName: "dashboard" */ '../pages/DashboardPage/DashboardPage'));
const FaqPage = lazy(() => import(/* webpackChunkName: "dashboard" */ '../pages/FaqPage/FaqPage'));
const CompaniesPage = lazy(() => import(/* webpackChunkName: "companies" */ '../pages/CompaniesPage/CompaniesPage'));
const CompanyPage = lazy(() => import(/* webpackChunkName: "company" */ '../pages/CompanyPage/CompanyPage'));
const PropertyPage = lazy(() => import(/* webpackChunkName: "property" */ '../pages/PropertyPage/PropertyPage'));
const BuildingPage = lazy(() => import(/* webpackChunkName: "building" */ '../pages/BuildingPage/BuildingPage'));
const PropertiesPage = lazy(
  () => import(/* webpackChunkName: "all-properties" */ '../pages/PropertiesPage/PropertiesPage')
);
const TenantPage = lazy(
  () => import(/* webpackChunkName: "tenant-space" */ '../pages/TenantSpacePage/TenantSpacePage')
);
const GlobalProceduresPage = lazy(
  () => import(/* webpackChunkName: "global-procedures" */ '../pages/GlobalProceduresPage/GlobalProceduresPage')
);
const GlobalCommunityResourcesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "global-community-resources" */ '../pages/GlobalCommunityResourcesPage/GlobalCommunityResourcesPage'
    )
);
const AccountSettingsPage = lazy(
  () => import(/* webpackChunkName: "settings" */ '../pages/AccountSettingsPage/AccountSettingsPage')
);

// Auth
const LoginPage = lazy(() => import(/* webpackChunkName: "login" */ '../pages/LoginPage/LoginPage'));
const ForgotPasswordPage = lazy(
  () => import(/* webpackChunkName: "forgot-password" */ '../pages/ForgotPasswordPage/ForgotPasswordPage')
);
const ResetPasswordPage = lazy(
  () => import(/* webpackChunkName: "reset-password" */ '../pages/ResetPasswordPage/ResetPasswordPage')
);

// SignUp
const UserInfoPage = lazy(() => import(/* webpackChunkName: "sign-up" */ '../pages/UserInfoPage/UserInfoPage'));
const CompanyInfoPage = lazy(
  () => import(/* webpackChunkName: "sign-up" */ '../pages/CompanyInfoPage/CompanyInfoPage')
);
const MembersPage = lazy(() => import(/* webpackChunkName: "sign-up" */ '../pages/MembersPage/MembersPage'));
const SignupPropertiesPage = lazy(
  () => import(/* webpackChunkName: "sign-up" */ '../pages/SignupPropertiesPage/SignupPropertiesPage')
);

export const privateRoutes = [
  {
    index: true,
    path: '',
    component: <DashboardPage />,
    access: [UserRoles.GLOBAL_ADMIN, UserRoles.COMPANY_USER],
  },
  {
    index: false,
    path: 'companies/*',
    component: <CompaniesPage />,
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'companies/:companyId/*',
    component: <CompanyPage />,
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'company/*',
    component: <CompanyPage />,
    access: [UserRoles.COMPANY_USER],
  },
  {
    index: false,
    path: 'properties',
    component: <PropertiesPage />,
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'companies/:companyId/properties/:propertyId/*',
    component: <PropertyPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'company/properties/:propertyId/*',
    component: <PropertyPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'company/properties/:propertyId/buildings/:buildingId/*',
    component: <BuildingPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'companies/:companyId/properties/:propertyId/buildings/:buildingId/*',
    component: <BuildingPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'company/properties/:propertyId/buildings/:buildingId/tenant-space/:tenantId/*',
    component: <TenantPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'companies/:companyId/properties/:propertyId/buildings/:buildingId/tenant-space/:tenantId/*',
    component: <TenantPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'procedures',
    component: <GlobalProceduresPage />,
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'community-resources',
    component: <GlobalCommunityResourcesPage />,
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'settings',
    component: <AccountSettingsPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'users',
    component: <AllUsersPage />,
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: 'faq',
    component: <FaqPage />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    index: false,
    path: '*',
    component: <ErrorPage type={ErrorPageTypes.notFound} />,
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
];

export const signUpRoutes = [
  {
    index: true,
    path: 'profile/:hash',
    component: <UserInfoPage />,
  },
  {
    index: false,
    path: 'company',
    component: (
      <UserProvider>
        <CompanyInfoPage />
      </UserProvider>
    ),
  },
  {
    index: false,
    path: 'members',
    component: (
      <UserProvider>
        <MembersPage />
      </UserProvider>
    ),
  },
  {
    index: false,
    path: 'properties',
    component: (
      <UserProvider>
        <SignupPropertiesPage />
      </UserProvider>
    ),
  },
  {
    index: false,
    path: '*',
    component: <ErrorPage type={ErrorPageTypes.notFound} />,
  },
];

export const loginRoutes = [
  {
    index: true,
    path: '',
    component: <LoginPage />,
  },
  {
    index: false,
    path: 'forgot',
    component: <ForgotPasswordPage />,
  },
  {
    index: false,
    path: 'reset-password/:hash',
    component: <ResetPasswordPage />,
  },
  {
    index: false,
    path: '*',
    component: <ErrorPage type={ErrorPageTypes.notFound} />,
  },
];

export const baseConfig = [
  {
    path: '/',
    icon: IconTypes.Dashboard,
    label: 'My Dashboard',
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
  {
    path: '/companies',
    icon: IconTypes.Portfolio,
    label: <span data-cy="companies-button">Companies</span>,
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    path: '/company',
    icon: IconTypes.Portfolio,
    label: 'Company Profile',
    access: [UserRoles.COMPANY_USER],
  },
  {
    path: '/properties',
    icon: IconTypes.Property,
    label: 'Properties',
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    path: '/procedures',
    icon: IconTypes.File,
    label: 'Default Procedures',
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    path: '/company/procedures',
    icon: IconTypes.File,
    label: 'Company Procedures',
    access: [UserRoles.COMPANY_USER],
  },
  {
    path: '/company/properties',
    icon: IconTypes.Property,
    label: 'Properties',
    access: [UserRoles.COMPANY_USER],
  },
  {
    path: '/community-resources',
    icon: IconTypes.Folders,
    label: 'Community Resources',
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    path: '/company/media',
    icon: IconTypes.Files,
    label: 'Media',
    access: [UserRoles.COMPANY_USER],
  },
  {
    path: '/users',
    icon: IconTypes.Group,
    label: 'Users',
    access: [UserRoles.GLOBAL_ADMIN],
  },
  {
    path: '/faq',
    icon: IconTypes.Questionmark,
    label: 'FAQ',
    access: [UserRoles.COMPANY_USER, UserRoles.GLOBAL_ADMIN],
  },
];

export const profileRoutes = [
  {
    path: '/settings',
    icon: IconTypes.Gear,
    label: 'Account Settings',
  },
];

export const isCompanyPage = (location: string) =>
  (matchRoutes([{ path: 'company' }, { path: 'companies/:companyId' }], location)?.length as number) > 0;

export const isPropertyPage = (location: string) =>
  (matchRoutes(
    [
      { path: 'properties/:propertyId' },
      { path: 'properties/:propertyId' },
      { path: 'company/properties/:propertyId' },
      { path: 'companies/:companyId/properties/:propertyId' },
    ],
    location
  )?.length as number) > 0;

export const isBuildingPage = (location: string) =>
  (matchRoutes(
    [
      { path: 'company/properties/:propertyId/buildings/:buildingId' },
      { path: 'companies/:companyId/properties/:propertyId/buildings/:buildingId' },
    ],
    location
  )?.length as number) > 0;

export const isTenantSpacePage = (location: string) =>
  (matchRoutes(
    [
      { path: 'company/properties/:propertyId/buildings/:buildingId/tenant-space/:tenantSpaceId' },
      { path: 'company/properties/:propertyId/buildings/:buildingId/tenant-space/:tenantSpaceId' },
      { path: 'companies/:companyId/properties/:propertyId/buildings/:buildingId/tenant-space/:tenantSpaceId' },
      { path: 'companies/:companyId/properties/:propertyId/buildings/:buildingId/tenant-space/:tenantSpaceId' },
    ],
    location
  )?.length as number) > 0;
