import { List } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import propertyAvatar from '../../../assets/images/propertyAvatar.png';
import userAvatar from '../../../assets/images/userAvatar.png';
import { SCREEN_STATUS } from '../../../types';
import { theme } from '../themes/default';
import { Text } from '../Typography';

const listItemBaseInfoWidth = {
  medium: '65%',
  large: '50%',
  small: '50%',
  extraSmall: '56px',
};

export const ListItem = styled(List.Item)`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${theme.grey};

  &:last-child {
    border-bottom: none;
  }
`;

export const ListItemLink = styled(Link)`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  align-items: center;
  flex-wrap: nowrap;

  &:hover {
    background: #fff3ee66;
  }
`;

export const ListItemContent = styled.div<{ withActions?: boolean }>`
  width: ${({ withActions }) => (withActions ? '95%' : '100%')};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ListItemBaseStyles = `
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  & > span {
    max-width: 80%;
  }
`;

export const ListItemBaseInfo = styled.div<{ screenWidth: SCREEN_STATUS }>`
  width: ${({ screenWidth }) => listItemBaseInfoWidth[screenWidth]};
  ${ListItemBaseStyles}
`;

export const ListItemBaseLink = styled(Link)<{ screenWidth: SCREEN_STATUS }>`
  width: ${({ screenWidth }) => listItemBaseInfoWidth[screenWidth]};
  ${ListItemBaseStyles}
`;

export const ListItemAvatar = styled.div<{ avatar?: string; isExtraSmallScreen?: boolean }>`
  background: url(${({ avatar }) => avatar ?? userAvatar}), ${theme.grey};
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '' : 'margin-right: 16px;')};
  border-radius: 50%;
  display: inline-block;
`;

export const ListItemImage = styled.div<{ logo?: string }>`
  background: url(${({ logo }) => logo || propertyAvatar});
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 16px;
  border-radius: 4px;
  position: relative;
`;

export const ListItemAddInfo = styled.div<{
  isLargeScreen?: boolean;
  isExtraSmallScreen?: boolean;
  align?: 'center' | 'flex-end';
}>`
  ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? 'width: fit-content' : '')};
  justify-content: ${({ align }) => align || 'center'};
  ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? 'margin: 0 10px;' : 'width: 90%;')};
  gap: 8px;
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const listItemInnerInfoWidth = {
  medium: '35%',
  large: '50%',
  small: '50%',
  extraSmall: '100%',
};

export const ListItemInnerContent = styled.div<{ screenWidth: SCREEN_STATUS; isColumn?: boolean }>`
  display: flex;
  width: ${({ screenWidth }) => listItemInnerInfoWidth[screenWidth]};
  ${({ isColumn }) => (isColumn ? 'flex-direction: column; gap: 8px' : 'align-items: center')};
`;

export const ListItemInnerContentColumn = styled.div<{ screenWidth: SCREEN_STATUS; fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ screenWidth }) => listItemInnerInfoWidth[screenWidth]};
`;

export const ListCardStatus = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 5px;
  }
`;

export const ListCardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ListCardTitle = styled(Text)<{ isExtraSmallScreen?: boolean }>`
  ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '' : 'margin-bottom: 4px;')};
  transition: ${theme.transitionColor};

  &:hover {
    color: ${theme.primary};
  }
  span + span {
    display: flex;
    align-items: center;
    font-size: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '12px' : '14px')};
    gap: 5px;
  }
`;

export const ListCardContent = styled.div<{ isExtraSmallScreen?: boolean; centered?: boolean }>`
  padding: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '6px 16px' : '12px 16px')};
  display: flex;
  flex-direction: column;
  ${({ centered }) => (centered ? 'align-items: center' : '')};
`;

export const ListCardActions = styled.div<{ white?: boolean }>`
  width: 32px;
  height: 32px;
  background: ${({ white }) => (white ? theme.white : theme.mediumGrey)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 100;
`;

export const ListCardContainer = styled.div<{ isExtraSmallScreen?: boolean }>`
  width: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '166px' : '224px')};
  height: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '136px' : '253px')};
  border-radius: ${theme.borderRadius};
  background: ${theme.white};
  box-shadow: ${theme.boxShadow};
  position: relative;
`;

export const ListCardContainerSmall = styled.div<{ isExtraSmallScreen?: boolean }>`
  width: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '166px' : '224px')};
  height: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '150px' : '200px')};
  border-radius: ${theme.borderRadius};
  background: ${theme.white};
  box-shadow: ${theme.boxShadow};
  position: relative;
`;
