import './antd.less';
import 'react-image-gallery/styles/css/image-gallery.css';

import ReactDOM from 'react-dom/client';

import App from './App';
import { initErrorLogging } from './utils/ErrorLogger';

initErrorLogging();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
