import { useCallback, useEffect } from 'react';

import { LocalStorage } from '../../../utils/LocalStorage';
import Icon, { IconTypes } from '../Icon/Icon';
import { ListIconWrapper } from '../List';
import { theme } from '../themes/default';

export type ListToggleLayout = 'list' | 'grid';

export enum ListEntityName {
  TeamMember = 'teamMemberList',
  Property = 'propertyList',
  Companies = 'companiesList',
  Properties = 'propertiesList',
  Attachments = 'attachmentList',
}

interface ListToggleProps {
  setListLayout(arg0: ListToggleLayout): void;
  layout?: ListToggleLayout;
  listEntityName?: ListEntityName;
}

export function ListToggle({ layout = 'list', setListLayout, listEntityName }: ListToggleProps) {
  const isListLayout = layout === 'list';

  useEffect(() => {
    if (!listEntityName) return;

    const listLayout = LocalStorage.getItem(listEntityName) as ListToggleLayout;

    if (!listLayout) return;
    setListLayout(listLayout);
  }, [listEntityName, setListLayout]);

  const handleChange = useCallback(
    (newLayout: ListToggleLayout) => {
      if (listEntityName) {
        LocalStorage.setItem(listEntityName, newLayout);
      }
      setListLayout(newLayout);
    },
    [listEntityName, setListLayout]
  );

  return (
    <>
      <ListIconWrapper onClick={() => handleChange('list')}>
        <Icon icon={IconTypes.List} fillColor={isListLayout ? theme.primary : theme.secondary} />
      </ListIconWrapper>

      <ListIconWrapper onClick={() => handleChange('grid')}>
        <Icon icon={IconTypes.Grid} fillColor={isListLayout ? theme.secondary : theme.primary} />
      </ListIconWrapper>
    </>
  );
}
