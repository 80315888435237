import styled from 'styled-components';

import { Modal } from '../ui/Modal';
import { theme } from '../ui/themes/default';
import { StepStatus } from './types';

export const StepImage = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

export const StepImageWrapper = styled.div`
  background-color: #f6f6fa;
  max-height: 237px;
  border-bottom: 1px solid #e6e6f0;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }
`;

export const StepCopy = styled.div`
  padding: 55px 32px 50px;

  h1 {
    color: ${theme.mainBlack}!important;
    margin-bottom: 8px;
  }
`;

export const StepsFooter = styled.div`
  position: relative;
  padding: 0 32px 10px;
  display: flex;
  justify-content: space-between;
`;

export const PopupButton = styled.div`
  button {
    width: 180px;
  }
`;

export const OnboardingWrapper = styled.div<{ isSmallScreen: boolean }>`
  min-height: 516px;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isSmallScreen
      ? `${StepCopy} {
    padding: 32px 24px;
  }

  ${StepImageWrapper} {
    height: 153px;
  }

  ${StepsFooter} {
    padding: 0 24px 24px;
  }

  ${PopupButton} {
    position: static;

    button {
      width: 100%;
    }
  }
  
  `
      : ''}
`;

const right = {
  current: '0%',
  previous: '100%',
  next: '-100%',
};

export const Step = styled.div<{ stepStatus: StepStatus }>`
  position: absolute;
  width: 100%;
  left: ${({ stepStatus }) => right[stepStatus]};
  transition: left ease-in-out 0.35s;
`;

export const StepsWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
`;
