import styled from 'styled-components';

import userRolesImage from '../../assets/images/sign-up-tooltip.svg';

export const PopoverContent = styled.div`
  width: 240px;
  padding: 12px 8px;
  position: relative;
`;

export const PopoverTitle = styled.div<{ withImage?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  ${({ withImage }) => withImage && { marginTop: '130px' }};

  > span {
    margin-left: 10px;
  }
`;

export const ActionsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const DescriptionSection = styled.div`
  margin-top: 5px;
`;

export const ArrowsWrapper = styled.div`
  width: 48px;
  display: flex;
  justify-content: flex-end;

  > div:nth-of-type(2) {
    margin-left: 16px;
  }
`;

export const TeamImage = styled.div`
  height: 130px;
  width: 272px;
  background: url(${userRolesImage});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -12px;
  left: -16px;
`;

export const MembersTooltip = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const TextWrapper = styled.div`
  > span:first-child {
    margin-right: 4px;
  }
`;
