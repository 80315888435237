import { useExportAllUsers, useGetAllUsers } from '@securecore-new-application/securecore-datacore';
import {
  AllUsersOrderBy,
  Company,
  OrderByDirection,
  Profile,
  User,
  UserRoles,
} from '@securecore-new-application/securecore-datacore/lib/types';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';

import { getUserTitle, PER_PAGE } from '../../constants';
import { useGetScreenStatus } from '../../hooks';
import { SCREEN_STATUS } from '../../types';
import { getDateString } from '../../utils/dateTime';
import { getFullName } from '../../utils/getFullName';
import { ChangePasswordModal } from '../ChangePasswordModal';
import { SortEntityName } from '../SortDropdown';
import { StopPropagation } from '../StopPropagation';
import { DeleteDropdownItem } from '../TeamMembers/TeamMembersList/TeamMembersList.styles';
import { DataTable, SortingOption, useGetColumnSearch } from '../ui/DataTable';
import { StyledLink, TableWrapper } from '../ui/DataTable/DataTable.styles';
import { Dropdown } from '../ui/Dropdown';
import Icon, { IconTypes } from '../ui/Icon/Icon';
import { ListEntityName } from '../ui/ListToggle';
import { theme } from '../ui/themes/default';
import { sendCSV } from './csv';

export interface DataType {
  user: string;
  email: string;
  company: Company;
  userName: string;
  title: string;
  createdAt: string;
  key: number;
  id: number;
  pending: boolean;
  role: UserRoles;
}

const sortingOptions = [
  {
    label: 'Name A-Z',
    value: AllUsersOrderBy.TITLE,
    key: 'title-asc',
    direction: OrderByDirection.ASC,
  },
  {
    label: 'Name Z-A',
    value: AllUsersOrderBy.TITLE,
    key: 'title-desc',
    direction: OrderByDirection.DESC,
  },
  {
    label: 'UserName A-Z',
    value: AllUsersOrderBy.USERNAME,
    key: 'username-asc',
    direction: OrderByDirection.ASC,
  },
  {
    label: 'UserName Z-A',
    value: AllUsersOrderBy.USERNAME,
    key: 'username-desc',
    direction: OrderByDirection.DESC,
  },
  {
    label: 'Earliest',
    value: AllUsersOrderBy.CREATED_AT,
    key: 'cat-asc',
    direction: OrderByDirection.ASC,
  },
  {
    label: 'Latest',
    value: AllUsersOrderBy.CREATED_AT,
    key: 'cat-desc',
    direction: OrderByDirection.DESC,
  },
];

export function AllUsersTable() {
  const [exportAllUsers] = useExportAllUsers();
  const [selectedUser, setSelectedUser] = useState<DataType>();
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [processedData, setProcessedData] = useState<DataType[]>([]);
  const [count, setCount] = useState<number>(0);
  const [filter, setFilter] = useState<Record<string, string>>({});

  const [searchParams, setSearchParams] = useState({
    value: AllUsersOrderBy.CREATED_AT,
    direction: OrderByDirection.DESC,
    page: 1,
  });

  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;

  const { data, loading } = useGetAllUsers({
    variables: {
      pagination: {
        offset: 0,
        limit: searchParams.page * PER_PAGE,
        orderBy: searchParams.value,
        orderByDirection: searchParams.direction,
      },
      filter,
    },
  });

  useEffect(() => {
    const mappedUsers = data?.getAllUsers.list.map((user: User): DataType => {
      return {
        pending: user.pendingInvitation === true,
        company: user.company as Company,
        userName: user.userName as string,
        user: getFullName(user.profile as Profile),
        title: user.title as string,
        email: user.profile?.email as string,
        createdAt: getDateString(user.createdAt),
        key: user.id,
        id: user.id,
        role: user.role,
      };
    });

    if (mappedUsers) {
      setProcessedData(mappedUsers);
      setCount(data?.getAllUsers.count || PER_PAGE);
    }
  }, [data]);

  const onSort = useCallback((item: SortingOption) => {
    setSearchParams({
      direction: item.direction,
      value: item.value as AllUsersOrderBy,
      page: 1,
    });
  }, []);

  const onLoadMore = useCallback(() => {
    setSearchParams({
      ...searchParams,
      page: searchParams.page + 1,
    });
  }, [searchParams]);

  const onReset = useCallback((item: DataType) => {
    setSelectedUser(item);
    setOpenChangeModal(true);
  }, []);

  const onCloseResetPassword = useCallback(() => {
    setSelectedUser(undefined);
    setOpenChangeModal(false);
  }, []);

  const onExport = useCallback(async () => {
    const { data: exportData } = await exportAllUsers({
      variables: {
        pagination: {
          orderBy: searchParams.value,
          orderByDirection: searchParams.direction,
        },
        filter,
      },
    });

    sendCSV(filter, exportData?.exportAllUsers.list);
  }, [exportAllUsers, filter, searchParams.direction, searchParams.value]);

  const getDropdownItems = useCallback(
    (item: DataType) => {
      if (item.role === UserRoles.COMPANY_USER && !item.company) {
        return [];
      }
      const resetPasswordAction = {
        key: 'reset',
        label: <DeleteDropdownItem>Change Password</DeleteDropdownItem>,
        action: () => onReset(item),
      };

      return [...(item.pending ? [] : [resetPasswordAction])];
    },
    [onReset]
  );

  const columns: ColumnsType<DataType> = [
    {
      title: 'Username',
      key: 'userName',
      width: '20%',
      ...useGetColumnSearch({
        columnIndex: 'userName',
        filter,
        setFilter,
        render: (_, { userName }) => <span>{userName}</span>,
      }),
    },
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...useGetColumnSearch({
        columnIndex: 'name',
        filter,
        setFilter,
        render: (_, { user, pending }) => (
          <span>
            {!pending ? (
              `${user}`
            ) : (
              <Tooltip title="Invitation is pending">
                <Icon icon={IconTypes.Time} />
                &nbsp;
                {user}
              </Tooltip>
            )}
          </span>
        ),
      }),
    },
    {
      title: 'Email',
      key: 'email',
      width: '20%',
      ...useGetColumnSearch({
        columnIndex: 'email',
        filter,
        setFilter,
        render: (_, { email }) => <span>{email}</span>,
      }),
    },
    {
      title: 'Company',
      dataIndex: ['company', 'name'],
      key: 'company',
      width: '10%',
      ...useGetColumnSearch({
        columnIndex: 'company',
        filter,
        setFilter,
        render: (_, { company }) =>
          company ? <StyledLink to={`/companies/${company.id}`}>{company.name}</StyledLink> : null,
      }),
    },
    {
      title: 'Title',
      key: 'title',
      width: '20%',
      render: (_, { title }) => <span>{getUserTitle(title)}</span>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '10%',
      render: (_, { createdAt }) => <span>{getDateString(createdAt)}</span>,
    },
    {
      title: 'Options',
      dataIndex: 'options',
      align: 'center',
      key: 'options',
      render: (_, item) => {
        const dropdownItems = getDropdownItems(item);

        if (dropdownItems.length > 0) {
          return (
            <StopPropagation>
              <Dropdown dropdownItems={getDropdownItems(item)}>
                <div style={{ cursor: 'pointer' }}>
                  <Icon icon={IconTypes.Dots} fillColor={theme.secondary} />
                </div>
              </Dropdown>
            </StopPropagation>
          );
        }
      },
    },
  ];

  return (
    <>
      <TableWrapper isSmallScreen={isSmallScreen}>
        <DataTable<DataType>
          needsLoadMore={processedData.length < count}
          tableTitle="All Users"
          data={processedData}
          loading={loading}
          columns={columns}
          sortable
          sortingOptions={sortingOptions}
          onSort={onSort}
          sortEntityName={SortEntityName.AllUsers}
          onLoadMore={onLoadMore}
          entityName={ListEntityName.Companies}
          exportable
          onExport={onExport}
        />
      </TableWrapper>
      <ChangePasswordModal
        userId={selectedUser?.id}
        userName={selectedUser?.userName}
        visible={openChangeModal}
        onCloseModal={onCloseResetPassword}
      />
    </>
  );
}
