import { ButtonProps as AntButtonProps } from 'antd';
import { ReactNode } from 'react';

import {
  AddButton,
  AdditionalButton,
  ButtonLabel,
  ButtonSizeType,
  EmergencyButton,
  IconButtonStyled,
  InfoButtonStyled,
  OutlineButton,
  PrimaryButton,
  SecondaryButton,
  TextButtonStyled,
} from './Button.styles';

export type ButtonType = 'primary' | 'secondary' | 'outline' | 'add' | 'emergency' | 'additional';

interface ButtonProps extends AntButtonProps {
  children?: string | ReactNode;
  variant?: ButtonType;
  disabled?: boolean;
  buttonSize?: ButtonSizeType;
  fullWidth?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  dashed?: boolean;
}

interface IconButtonProps extends AntButtonProps {
  icon: ReactNode;
  disabled?: boolean;
  buttonSize?: ButtonSizeType;
  round?: boolean;
  outlined?: boolean;
}

export function Button({
  children,
  variant = 'primary',
  disabled,
  buttonSize = 'medium',
  fullWidth,
  leftIcon,
  rightIcon,
  ...props
}: ButtonProps) {
  switch (variant) {
    case 'primary':
      return (
        <PrimaryButton type="primary" disabled={disabled} buttonSize={buttonSize} block={fullWidth} {...props}>
          {leftIcon}
          <ButtonLabel buttonSize={buttonSize}>{children}</ButtonLabel>
          {rightIcon}
        </PrimaryButton>
      );
    case 'secondary':
      return (
        <SecondaryButton type="default" disabled={disabled} buttonSize={buttonSize} block={fullWidth} {...props}>
          {leftIcon}
          {children && <ButtonLabel>{children}</ButtonLabel>}
          {rightIcon}
        </SecondaryButton>
      );
    case 'outline':
      return (
        <OutlineButton type="default" disabled={disabled} buttonSize={buttonSize} block={fullWidth} {...props}>
          {leftIcon}
          {children && <ButtonLabel>{children}</ButtonLabel>}
          {rightIcon}
        </OutlineButton>
      );
    case 'additional':
      return (
        <AdditionalButton type="default" disabled={disabled} buttonSize={buttonSize} block={fullWidth} {...props}>
          {leftIcon}
          {children && <ButtonLabel>{children}</ButtonLabel>}
          {rightIcon}
        </AdditionalButton>
      );
    case 'add':
      return (
        <AddButton type="primary" disabled={disabled} buttonSize={buttonSize} block={fullWidth} {...props}>
          <ButtonLabel buttonSize={buttonSize}>{children}</ButtonLabel>
          {rightIcon}
        </AddButton>
      );
    case 'emergency':
      return (
        <EmergencyButton type="primary" disabled={disabled} buttonSize={buttonSize} block={fullWidth} {...props}>
          {leftIcon}
          <ButtonLabel buttonSize={buttonSize}>{children}</ButtonLabel>
          {rightIcon}
        </EmergencyButton>
      );
    default:
      return (
        <PrimaryButton type="primary" disabled={disabled} buttonSize={buttonSize} block={fullWidth} {...props}>
          {leftIcon}
          {children && <ButtonLabel>{children}</ButtonLabel>}
          {rightIcon}
        </PrimaryButton>
      );
  }
}

export function TextButton({ leftIcon, children, rightIcon, disabled, fullWidth, ...props }: ButtonProps) {
  return (
    <TextButtonStyled disabled={disabled} block={fullWidth} {...props}>
      {leftIcon}
      {children && <ButtonLabel>{children}</ButtonLabel>}
      {rightIcon}
    </TextButtonStyled>
  );
}

export function InfoButton({
  leftIcon,
  children,
  rightIcon,
  disabled,
  dashed,
  onClick,
}: Pick<ButtonProps, 'leftIcon' | 'children' | 'rightIcon' | 'disabled' | 'dashed' | 'onClick'>) {
  return (
    <InfoButtonStyled disabled={disabled} dashed={dashed} onClick={onClick}>
      {leftIcon}
      {children && <ButtonLabel>{children}</ButtonLabel>}
      {rightIcon}
    </InfoButtonStyled>
  );
}

export function IconButton({ icon, disabled, buttonSize = 'medium', round, outlined, ...props }: IconButtonProps) {
  return (
    <IconButtonStyled disabled={disabled} buttonSize={buttonSize} round={round} outlined={outlined} {...props}>
      {icon}
    </IconButtonStyled>
  );
}
