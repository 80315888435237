import { MouseEvent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function StopPropagation({ children }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={(e: MouseEvent) => e.stopPropagation()} role="button" tabIndex={0}>
      {children}
    </div>
  );
}
