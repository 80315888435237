import React from 'react';

import forbiddenError from '../../../assets/images/403.svg';
import notFound from '../../../assets/images/404.svg';
import serverError from '../../../assets/images/500.svg';
import deletedPage from '../../../assets/images/Deleted.svg';
import { useGetScreenStatus } from '../../../hooks';
import { SCREEN_STATUS } from '../../../types';
import { Button } from '../../ui/Button';
import { Text, Title } from '../../ui/Typography';
import { StyledResult } from './ErrorPage.styles';

export enum ErrorPageTypes {
  'notFound' = '404',
  'server' = '500',
  'forbidden' = '403',
  'unknown' = 'unknown',
  'deleted' = 'deleted',
}

const iconMap: Record<ErrorPageTypes, React.ReactNode> = {
  [ErrorPageTypes.notFound]: <img src={notFound} alt={ErrorPageTypes.notFound} />,
  [ErrorPageTypes.server]: <img src={serverError} alt={ErrorPageTypes.server} />,
  [ErrorPageTypes.unknown]: <img src={serverError} alt={ErrorPageTypes.unknown} />,
  [ErrorPageTypes.forbidden]: <img src={forbiddenError} alt={ErrorPageTypes.forbidden} />,
  [ErrorPageTypes.deleted]: <img src={deletedPage} alt={ErrorPageTypes.deleted} />,
};

const titlesMap: Record<ErrorPageTypes, string> = {
  [ErrorPageTypes.notFound]: 'Ooops....Page not found!',
  [ErrorPageTypes.server]: 'Something went wrong',
  [ErrorPageTypes.unknown]: 'Something went wrong',
  [ErrorPageTypes.forbidden]: 'You’re not permitted to view this page',
  [ErrorPageTypes.deleted]: 'This page isn’t available',
};

const subTitlesMap: Record<ErrorPageTypes, React.ReactNode> = {
  [ErrorPageTypes.notFound]: null,
  [ErrorPageTypes.forbidden]: null,
  [ErrorPageTypes.server]: <Text variant="bodyRegular">Please try again or contact support</Text>,
  [ErrorPageTypes.unknown]: <Text variant="bodyRegular">Please try again or contact support</Text>,
  [ErrorPageTypes.deleted]: null,
};

export interface ErrorPageProps {
  type: ErrorPageTypes;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ type }) => {
  const currentScreen = useGetScreenStatus();

  const isExtraSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;
  const goHome = () => {
    window.location.pathname = '/';
  };

  return (
    <StyledResult
      isExtraSmallScreen={isExtraSmallScreen}
      icon={iconMap[type]}
      title={<Title variant="heading2">{titlesMap[type]}</Title>}
      subTitle={subTitlesMap[type]}
      extra={<Button onClick={goHome}>Back to Home</Button>}
    />
  );
};
