import styled from 'styled-components';

import { theme } from '../themes/default';

export const Toolbar = styled.div<{ isSmallScreen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  background: ${theme.white};
  box-shadow: ${theme.boxShadow};
  border-radius: 8px;
  gap: ${({ isSmallScreen }) => (isSmallScreen ? '6px' : '16px')};
  padding: ${({ isSmallScreen }) => (isSmallScreen ? '6px' : '16px')};
  width: fit-content;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const TextAreaToolbar = styled(Toolbar)`
  padding: 6px;
  box-shadow: none;
  background-color: ${theme.mediumGrey};
  margin-bottom: 6px;
  width: 100%;
  gap: 12px;
`;

export const TextAreaEditorWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 9px 13px;
  color: #323234;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dfdfe3;
  border-radius: 8px;
  transition: all 0.3s;

  ol,
  ul {
    padding-left: 1em;
  }
`;
export const StyledEdit = styled.div<{ isSmallScreen: boolean }>`
  h1 {
    ${({ isSmallScreen }) => (isSmallScreen ? 'font-size: 18px;' : '')};
    color: ${theme.darkGrey};
  }
`;

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
