import { useGetScreenStatus } from '../../hooks';
import { SCREEN_STATUS } from '../../types';
import { Text } from '../ui/Typography';
import { SignUpStepsWrapper, Step, Steps } from './SignUpSteps.styles';

interface Props {
  currentStep: number;
  totalSteps: number;
  label?: string;
}

export function SignUpSteps({ currentStep, totalSteps, label = 'Step' }: Props) {
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;

  const getCurrentStatus = (index: number) => {
    if (index === currentStep) return 'active';
    if (index < currentStep) return 'passed';

    return 'default';
  };

  return (
    <SignUpStepsWrapper isSmallScreen={isSmallScreen}>
      <Steps isSmallScreen={isSmallScreen}>
        {Array.from(Array(totalSteps).keys()).map((_, i) => (
          <Step key={i} status={getCurrentStatus(i + 1)} total={totalSteps} />
        ))}
      </Steps>
      <Text variant="bodySemibold">
        {label} {currentStep} of {totalSteps}
      </Text>
    </SignUpStepsWrapper>
  );
}
