import { DataCoreProvider } from '@securecore-new-application/securecore-datacore';
import * as Sentry from '@sentry/react';
import React, { lazy } from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ErrorPage, ErrorPageTypes } from './components/Errors';
import { MobileBanner } from './components/MobileBanner/MobileBanner';
import { PrivateRoute } from './components/PrivateRoute';
import { useGleap } from './hooks/useGleap';
import { loginRoutes, privateRoutes, signUpRoutes } from './routes';
import { dataCoreClient } from './utils/dataCoreClient';

const AuthContainer = lazy(() => import(/* webpackChunkName: "auth" */ './containers/AuthContainer'));
const MainContainer = lazy(() => import(/* webpackChunkName: "main-application" */ './containers/MainContainer'));
const SignUpContainer = lazy(() => import(/* webpackChunkName: "sign-up" */ './containers/SignUpContainer'));

function App() {
  useGleap();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage type={ErrorPageTypes.unknown} />}>
      <DataCoreProvider client={dataCoreClient.httpClient}>
        {isMobile && <MobileBanner />}
        <Router>
          <Routes>
            <Route path="/" element={<MainContainer />}>
              {privateRoutes.map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  index={item.index}
                  element={<PrivateRoute access={item.access || []}>{item.component}</PrivateRoute>}
                />
              ))}
            </Route>
            <Route path="signup" element={<SignUpContainer />}>
              {signUpRoutes.map((item) => (
                <Route key={item.path} path={item.path} index={item.index} element={item.component} />
              ))}
            </Route>
            <Route path="auth" element={<AuthContainer />}>
              {loginRoutes.map((item) => (
                <Route key={item.path} path={item.path} index={item.index} element={item.component} />
              ))}
            </Route>
            <Route path="*" element={<ErrorPage type={ErrorPageTypes.notFound} />} />
          </Routes>
        </Router>
      </DataCoreProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
