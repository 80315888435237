import styled from 'styled-components';

import { theme } from '../themes/default';
import { DropdownItemsLayout, DropdownPosition } from './Dropdown';

export const DropdownItem = styled.div`
  min-height: 34px;
  min-width: 120px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: ${theme.transitionBG};
  white-space: nowrap;
  text-align: left;

  svg path {
    transition: ${theme.transitionFill};
  }

  &:hover {
    background: ${theme.dropdownItemHover};

    svg path {
      fill: ${theme.orange} !important;
    }
  }
`;

export const DropdownContent = styled.div<{ position: DropdownPosition; itemsLayout: DropdownItemsLayout }>`
  position: absolute;
  z-index: 1000;
  top: 4px;
  ${({ position }) => (position === 'left' ? 'left: 0' : 'right: 0')};
  ${({ itemsLayout }) => (itemsLayout === 'horizontal' ? 'display: flex' : '')};
  background-color: ${theme.white};
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  padding: ${({ itemsLayout }) => (itemsLayout === 'horizontal' ? '4px 16px' : '4px 0')};

  ${DropdownItem} {
    flex-direction: ${({ itemsLayout }) => (itemsLayout === 'horizontal' ? 'column' : 'row')};
    min-width: ${({ itemsLayout }) => (itemsLayout === 'horizontal' ? 'initial' : '120px')};
    border-radius: ${({ itemsLayout }) => (itemsLayout === 'horizontal' ? '8px' : 'initial')};
  }
`;
