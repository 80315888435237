import { useCallback, useMemo, useState } from 'react';

import { useContentItem, useGetScreenStatus } from '../../../hooks';
import { SCREEN_STATUS } from '../../../types';
import { toTitleCase } from '../../../utils/text';
import { Onboarding } from '../../Onboarding';
import { ONBOARDING_ENTITIES } from '../../Onboarding/types';
import { Button, IconButton } from '../Button';
import { ContentItem } from '../ContentItem';
import { Dropdown } from '../Dropdown';
import Icon, { IconTypes } from '../Icon/Icon';
import { theme } from '../themes/default';
import { Title } from '../Typography';
import { ContentDrawer, ContentItemHeader, ContentItemTitle, IconWrapper } from './ContentItemDrawer.styles';

export enum ContentItemEntityName {
  Evacuation = 'Evacuation Procedure',
  Faq = 'FAQ',
  Procedure = 'procedure',
  MediaContact = 'Media Contact Procedure',
  Shelter = 'Shelter In Place Protocols',
  InsurancePolicy = 'Insurance Policy',
  MasterPolicy = 'Master Policy',
}

interface Props {
  entity: ContentItemEntityName;
  isOpen: boolean;
  onClose: () => void;
  onComplete: (contentItem: string) => void;
  content: string;
  restrictEdit?: boolean;
}

export function ContentItemDrawer({ entity, isOpen, onClose, onComplete, content, restrictEdit = false }: Props) {
  const [isEditable, setIsEditable] = useState(!restrictEdit && !content);
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;
  const [contentItem, { addContentItem, deleteContentItem, moveContentItem, saveContentItem, cleanUp }] =
    useContentItem({
      stringContent: content,
    });

  const handleComplete = useCallback(async () => {
    await onComplete(JSON.stringify(cleanUp()));
    setIsEditable(false);
  }, [cleanUp, onComplete]);

  const handleClose = useCallback(() => {
    onClose();
    setIsEditable(false);
  }, [onClose]);

  const dropdownItems = useMemo(
    () => [
      {
        key: 'edit',
        label: `Edit ${entity}`,
        action: () => setIsEditable(true),
      },
    ],
    [entity]
  );

  return (
    <ContentDrawer
      open={isOpen}
      placement="right"
      onClose={handleClose}
      destroyOnClose
      width="60%"
      isSmallScreen={isSmallScreen}
    >
      <ContentItemHeader isSmallScreen={isSmallScreen}>
        <ContentItemTitle isSmallScreen={isSmallScreen}>
          <IconButton
            icon={<Icon icon={IconTypes.AngleLeft} fillColor={theme.mainBlack} />}
            round
            buttonSize="large"
            onClick={handleClose}
          />
          <Title color="primary" variant="heading3">
            {entity}
          </Title>
        </ContentItemTitle>

        {isEditable ? (
          <Button onClick={handleComplete} disabled={false}>
            Save {toTitleCase(entity)}
          </Button>
        ) : (
          !restrictEdit && (
            <Dropdown dropdownItems={dropdownItems}>
              <IconWrapper>
                <Icon icon={IconTypes.Dots} fillColor={theme.secondary} />
              </IconWrapper>
            </Dropdown>
          )
        )}
      </ContentItemHeader>

      <ContentItem
        contentItem={contentItem}
        handleAddContentItem={addContentItem}
        handleSetContent={saveContentItem}
        handleDeleteContentItem={deleteContentItem}
        handleMoveContentItem={moveContentItem}
        readonly={!isEditable}
        entityName={entity}
      />
      <Onboarding entity={ONBOARDING_ENTITIES.CONTENT_ITEM} />
    </ContentDrawer>
  );
}
