/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { theme } from '../ui/themes/default';

const stepBackgroundConfig = {
  active: theme.primary,
  passed: theme.stepItemPassed,
  default: theme.stepItem,
};

type StepBackgroundStatus = 'active' | 'passed' | 'default';

const getStepWidth = (status: StepBackgroundStatus, total: number) => {
  const restSteps = total - 1;

  if (status === 'active') {
    return restSteps > 2 ? '38%' : '58%';
  }
  if (restSteps < 2) {
    return '40%';
  }

  const baseStepWidth = restSteps > 2 ? 60 : 40;

  return `${(baseStepWidth - restSteps) / restSteps}%`;
};

export const SignUpStepsWrapper = styled.div<{ isSmallScreen?: boolean }>`
  width: 100%;
  ${({ isSmallScreen }) => (isSmallScreen ? '' : 'max-width: 212px')};
  margin-top: 10px;
  margin-bottom: 16px;
`;

export const Step = styled.div<{ active?: boolean; status: StepBackgroundStatus; total: number }>`
  width: ${({ status, total }) => getStepWidth(status, total)};
  height: 4px;
  background: ${({ status }) => stepBackgroundConfig[status]};
  border-radius: ${theme.borderRadioBase};
  transition: width ease-in-out 0.35s;
`;

export const Steps = styled.div<{ isSmallScreen?: boolean }>`
  width: 100%;
  ${({ isSmallScreen }) => (isSmallScreen ? '' : 'max-width: 212px')};
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
