import styled from 'styled-components';

import { theme } from '../themes/default';

export const FormWrapper = styled.div`
  border-radius: ${theme.borderRadius};
  background: ${theme.white};
  box-shadow: ${theme.boxShadow};
  padding: 32px;
`;

export const FormContainer = styled.div<{ isSmallScreen?: boolean }>`
  width: 100%;
  max-width: 532px;
  margin: 50px auto 0;
  border-radius: ${theme.borderRadius};
  background: ${theme.white};
  box-shadow: ${theme.boxShadow};
  padding: ${({ isSmallScreen }) => (isSmallScreen ? '24px' : '32px')};
`;

export const FormCheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const FormCheckboxSider = styled.div`
  width: 30%;
`;

export const FormCheckboxContainer = styled.div`
  width: 70%;
`;
