// https://github.com/ant-design/ant-design/blob/4.x-stable/components/style/themes/default.less
const theme = {
  white: '#FFF',
  primary: '#F36D32',
  mainBlack: '#323234',
  secondary: '#323234',
  darkGrey: '#8E8FA1',
  grey: '#DFDFE3',
  mediumGrey: '#F6F6FA',
  mediumGreyA: 'rgba(246, 246, 250, 0.5)',
  lightGrey: '#FCFCFF',
  paleGrey: '#F8F8FD',
  darkenGrey: '#9B9EC0',
  orange: '#F36D32',
  lightOrange: '#FFF3EE',
  alertRed: '#EF4444',
  mainBackground: '#c7673a',
  mainBackgroundActive: '#be6338',
  borderFocus: '#644aff',
  secondaryBackgroundHover: '#ececf0',
  secondaryBackgroundActive: '#e7e7eb',
  lighterGray: '#9CA3AF',
  black: '#000',
  blackMain: '#323234',
  dropdownItemHover: '#F5F5F5',
  collapseButtonColor: '#babbd1',
  menuActiveItemBackground: '#F0F0F4',
  inputPlaceholderColor: '#040D6147',
  tableHoverBackground: '#fff3ee66',
  gridItemBorder: '#D4D5DE',
  popoverItemHover: '#F5F5F5',
  signUpBackground: '#FEEFE9',
  stepItem: '#040d6142',
  stepItemPassed: '#f36d3242',
  modalBorder: '#f0f0f0',
  boxShadow: '6px 6px 26px rgba(187, 187, 187, 0.16)',
  buttonDisabledBackground: 'rgba(243, 109, 50, 0.5)',
  transitionBG: 'background ease-in-out .35s',
  transitionTransform: 'transform ease-in-out .35s',
  transitionColor: 'color ease-in-out .35s',
  transitionOpacity: 'opacity ease-in-out .35s',
  transitionFill: 'fill ease-in-out .35s',
  textColor: '#484E5E',

  inputBorderRadius: '6px',
  borderRadioBase: '8px',
  borderRadius: '16px',
  inputHeightBase: '42px',
  inputHeightLarge: '56px',
};

exports.antdVariablesOverrides = {
  'btn-primary-bg': theme.primary,
  'btn-default-color': theme.secondary,
  'btn-border-style': 'none',
  'btn-text-color': theme.secondary,
  'btn-default-bg': theme.mediumGrey,
  'btn-border-radius-base': '8px',
  'btn-disable-bg': theme.buttonDisabledBackground,
  'btn-disable-color': theme.white,
  'text-color': theme.textColor,
  'font-family': 'Inter, sans-serif',
  'heading-1-size': '25px',
  'heading-2-size': '18px',
  'heading-3-size': '15px',
  'heading-color': theme.secondary,
  'typography-title-font-weight': '700',
  'typography-title-margin-top': '0',
  'typography-title-margin-bottom': '0',

  // Border
  '@border-radius-base': theme.borderRadioBase,

  // Input
  '@input-height-base': theme.inputHeightBase,
  '@input-height-lg': theme.inputHeightLarge,
  '@input-padding-vertical-base': '9px',
  '@input-padding-horizontal-base': '13px',
  '@input-border-color': theme.grey,
  '@input-color': theme.secondary,
  '@input-placeholder-color': theme.inputPlaceholderColor,
  '@input-disabled-bg': theme.lightGrey,
  '@input-hover-border-color': theme.orange,

  // Radio
  '@radio-size': '14px',
  '@radio-dot-color': theme.orange,

  // Radio buttons
  '@radio-button-bg': theme.white,
  '@radio-button-checked-bg': theme.white,
  '@radio-button-color': theme.white,
  '@radio-button-active-color': theme.orange,

  // Checkbox
  '@checkbox-size': '16px',
  '@checkbox-color': theme.orange,
  '@checkbox-check-color': theme.white,
  '@checkbox-check-bg': theme.white,
  '@checkbox-border-width': '1px',
  '@checkbox-border-radius': '2px',
  '@checkbox-group-item-margin-right': '8px',

  // Select
  '@select-border-color': theme.grey,
  '@select-item-selected-color': theme.white,
  '@select-item-selected-bg': theme.orange,
  '@select-item-active-bg': theme.lightOrange,
  '@select-background': theme.white,

  // Layout
  '@layout-sider-background': theme.mediumGrey,
  '@layout-sider-background-light': theme.mediumGrey,
  '@layout-body-background': theme.lightGrey,

  // Menu
  '@menu-bg': theme.mediumGrey,
  '@menu-item-height': '56px',
  '@menu-item-color': theme.secondary,
  '@menu-item-active-bg': theme.menuActiveItemBackground,
  '@menu-item-group-height': '56px',

  // Popover
  '@popover-arrow-width': 0,

  // Table
  '@table-header-bg': theme.white,
  '@table-header-color': theme.inputPlaceholderColor,
  '@table-row-hover-bg': theme.tableHoverBackground,

  // Text
  '@text-color': theme.secondary,

  // Form
  '@label-required-color': theme.darkGrey,
  '@label-color': theme.darkGrey,

  // Modal
  '@modal-body-padding': '32px',

  // Breadcrumbs
  '@breadcrumb-link-color': theme.darkGrey,
  '@breadcrumb-separator-color': theme.darkGrey,
  '@breadcrumb-font-size': '16px',
  '@breadcrumb-separator-margin': '0 13px',

  // Switch
  '@switch-color': theme.primary,
};

exports.theme = theme;
