import { OrderBy, OrderByDirection } from '@securecore-new-application/securecore-datacore/lib/types';
import { FC, useCallback, useEffect, useState } from 'react';

import { useGetScreenStatus } from '../../hooks';
import { SCREEN_STATUS } from '../../types';
import { LocalStorage } from '../../utils/LocalStorage';
import { Button } from '../ui/Button';
import { Dropdown, DropdownItemType } from '../ui/Dropdown';
import Icon, { IconTypes } from '../ui/Icon/Icon';
import { theme } from '../ui/themes/default';

export enum SortEntityName {
  Property = 'property',
  Properties = 'properties',
  Procedure = 'procedure',
  Alarm = 'alarm',
  History = 'history',
  Company = 'company',
  CommandCenter = 'commandCenter',
  Evacuation = 'evacuation',
  EmergencyResource = 'emergencyResource',
  MediaContact = 'mediaContact',
  AllUsers = 'allUsers',
  ShutOff = 'shutOff',
  Relocations = 'relocations',
  Shelters = 'shelters',
  Attachment = 'attachment',
  HazMats = 'hazMats',
  PolicyDetail = 'policyDetail',
}

export interface Props {
  sortingOptions?: DropdownItemType[];
  onChange: (item: DropdownItemType) => void;
  sortEntityName?: SortEntityName;
  defaultSortLabel?: string;
}

export const defaultSortingOptions = [
  {
    label: 'Name A-Z',
    value: OrderBy.NAME,
    key: 'name-asc',
    direction: OrderByDirection.ASC,
  },
  {
    label: 'Name Z-A',
    value: OrderBy.NAME,
    key: 'name-desc',
    direction: OrderByDirection.DESC,
  },
  {
    label: 'Latest',
    value: OrderBy.CREATED_AT,
    key: 'createdAt-desc',
    direction: OrderByDirection.DESC,
  },
  {
    label: 'Earliest',
    value: OrderBy.CREATED_AT,
    key: 'createdAt-asc',
    direction: OrderByDirection.ASC,
  },
];

export const SortDropdown: FC<Props> = ({
  sortingOptions = defaultSortingOptions,
  onChange,
  sortEntityName,
  defaultSortLabel = 'Sort By',
}) => {
  const [sortLabel, setSortLabel] = useState<string>(defaultSortLabel);
  const currentScreen = useGetScreenStatus();
  const isExtraSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;

  const handleChange = useCallback(
    (item: DropdownItemType) => {
      setSortLabel(item.label as string);
      onChange(item);

      if (sortEntityName) {
        LocalStorage.setItem(sortEntityName, item);
      }
    },
    [sortEntityName, onChange]
  );

  useEffect(() => {
    if (!sortEntityName) return;

    const soredSortItem: DropdownItemType = LocalStorage.getItem(sortEntityName) as DropdownItemType;

    if (!soredSortItem) return;

    setSortLabel(soredSortItem.label as string);
    onChange(soredSortItem);
  }, [sortEntityName, onChange]);

  return (
    <Dropdown dropdownItems={sortingOptions as DropdownItemType[]} onSelect={handleChange}>
      <Button
        variant="secondary"
        buttonSize={isExtraSmallScreen ? 'extraSmall' : 'medium'}
        rightIcon={<Icon icon={IconTypes.AngleDown} fillColor={theme.secondary} />}
      >
        {sortLabel}
      </Button>
    </Dropdown>
  );
};
