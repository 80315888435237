import { List as AntdList, ListProps as AntdListProps } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '../Typography';

interface ListProps<T> extends AntdListProps<T> {
  loading?: boolean;
  data: T[];
  renderItem: (item: T) => ReactNode;
  title?: string;
}

const StyledText = styled(Text)`
  margin-top: 32px;
  display: block;
`;

export function List<T>({ loading, data, renderItem, title, ...rest }: ListProps<T>) {
  return (
    <>
      {title && (
        <StyledText variant="bodyMedium" upperCase color="secondary">
          {title}
        </StyledText>
      )}

      <AntdList loading={loading} dataSource={data} renderItem={renderItem} {...rest} />
    </>
  );
}
