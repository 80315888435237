import { InputProps } from 'antd/lib/input';
import ReactInputMask from 'react-input-mask';

import Input from './Input';

interface InputMaskProps extends InputProps {
  mask: string;
}

export function InputMask({ mask, disabled, onBlur, onChange, value, ...rest }: InputMaskProps) {
  return (
    <ReactInputMask
      mask={mask}
      disabled={disabled}
      autoComplete="off"
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    >
      <Input {...rest} />
    </ReactInputMask>
  );
}
