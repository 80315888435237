import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetScreenStatus } from '../../hooks';
import { useOnboarding } from '../../hooks/useOnboarding';
import { SCREEN_STATUS } from '../../types';
import { SignUpSteps } from '../SignUpSteps';
import { Button } from '../ui/Button';
import { Text, Title } from '../ui/Typography';
import { STEPS } from './config';
import {
  OnboardingWrapper,
  PopupButton,
  Step,
  StepCopy,
  StepImage,
  StepImageWrapper,
  StepsFooter,
  StepsWrapper,
  StyledModal,
} from './OnboardingModal.styles';
import { ONBOARDING_ENTITIES, OnboardingStep, StepStatus } from './types';

interface OnboardingModalProps {
  entity: ONBOARDING_ENTITIES;
  forceShow: boolean;
  hideOnboarding: () => void;
}

export function OnboardingModal({ entity, hideOnboarding, forceShow }: OnboardingModalProps) {
  const { skipOnboardingStatus, skipOnboarding } = useOnboarding(entity);
  const [hideCompany, setHideCompany] = useState(true);
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;
  const [currentStep, setCurrentStep] = useState(1);
  const [steps] = useState(STEPS[entity]);
  const [totalSteps] = useState(Object.keys(steps).length);

  const incrementCounter = () => {
    setCurrentStep(currentStep + 1);
  };

  const closeOnboarding = () => {
    skipOnboarding();
    hideOnboarding?.();
  };

  const location = useLocation();

  // TODO: needs to be moved to the context, so that modal is mounted one time and uses the context entity name to display the content
  useEffect(() => {
    if (entity === ONBOARDING_ENTITIES.COMPANY && location.pathname === '/company/team') {
      setHideCompany(false);
    }
  }, [entity, location]);

  useEffect(() => {
    setCurrentStep(1);
  }, [forceShow]);

  const getStepStatus = (step: number): StepStatus => {
    if (step === currentStep) return 'current';
    if (step < currentStep) return 'next';

    return 'previous';
  };

  const getStep = (step: OnboardingStep, index: number) => (
    <Step stepStatus={getStepStatus(index + 1)}>
      <StepImageWrapper>
        <StepImage src={isSmallScreen ? step.imageMobile : step.image} />
      </StepImageWrapper>
      <StepCopy>
        <Title variant="heading3">{step.title}</Title>
        <Text>{step.content}</Text>
      </StepCopy>
    </Step>
  );

  const getSteps = () => <StepsWrapper>{Object.values(steps).map(getStep)}</StepsWrapper>;

  return (
    <StyledModal
      open={(!skipOnboardingStatus && hideCompany) || forceShow}
      onCancel={closeOnboarding}
      className="modalBox"
      destroyOnClose
    >
      <OnboardingWrapper isSmallScreen={isSmallScreen}>
        {getSteps()}
        <StepsFooter>
          <SignUpSteps currentStep={currentStep} totalSteps={Object.keys(steps).length} label="Update" />
          <PopupButton>
            {currentStep === totalSteps ? (
              <Button onClick={closeOnboarding} variant="primary" buttonSize="medium">
                Close
              </Button>
            ) : (
              <Button onClick={incrementCounter} variant="primary" buttonSize="medium">
                Continue
              </Button>
            )}
          </PopupButton>
        </StepsFooter>
      </OnboardingWrapper>
    </StyledModal>
  );
}
