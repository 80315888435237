import { dataCoreClient } from './dataCoreClient';
import { LocalStorage } from './LocalStorage';

export const logOut = async (): Promise<void> => {
  LocalStorage.removeItem('jwt');
  LocalStorage.removeItem('role');

  await dataCoreClient?.httpClient.clearStore();

  window.location.replace('/auth');
};
