import { Result } from 'antd';
import styled from 'styled-components';

export const StyledResult = styled(Result)<{ isExtraSmallScreen?: boolean }>`
  height: 100vh;
  .ant-result-extra {
    .ant-btn {
      display: inline-block;
    }
  }

  .ant-result-icon {
    img {
      width: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '100%' : 'auto')};
    }
  }

  .ant-result-title {
    .ant-typography {
      font-size: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '24px !important' : '32px !important')};
    }
  }
`;
