import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Area, MediaSize, Point, Size } from 'react-easy-crop';

import Icon, { IconTypes } from '../../ui/Icon/Icon';
import { theme } from '../../ui/themes/default';
import { ImageTransformations } from '../types';
import { Controls, Cropper, CropperContainer, SliderContainer, StyledSlider } from './ImageEditor.styles';

interface ImageEditorProps {
  file: string;
  transformations: ImageTransformations;
  setTransformations: Dispatch<SetStateAction<ImageTransformations>>;
}

export const minZoom = 1;
export const maxZoom = 5;
export const zoomStep = 0.1;
export const minRotation = -180;
export const maxRotation = 180;
export const rotationStep = 1;

const IconProps = {
  fillColor: theme.darkGrey,
  width: 32,
  height: 32,
};

export const ImageEditor = ({ file, transformations, setTransformations }: ImageEditorProps) => {
  const [cropSize, setCropSize] = useState<Size>({ width: 0, height: 0 });
  const [aspect, setAspect] = useState<number>(3 / 4);
  const onCropChange = useCallback(
    (crop: Point) => {
      setTransformations({
        ...transformations,
        crop,
      });
    },
    [setTransformations, transformations]
  );

  const onRotationChange = useCallback(
    (rotation: number) => {
      setTransformations({
        ...transformations,
        rotation,
      });
    },
    [setTransformations, transformations]
  );

  const onZoomChange = useCallback(
    (zoom: number) => {
      setTransformations({
        ...transformations,
        zoom,
      });
    },
    [setTransformations, transformations]
  );

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setTransformations({
        ...transformations,
        croppedAreaPixels,
      });
    },
    [setTransformations, transformations]
  );

  const onMediaLoaded = useCallback(({ width, height, naturalWidth, naturalHeight }: MediaSize) => {
    setCropSize({ width, height });
    setAspect(naturalHeight / naturalWidth);
  }, []);

  return (
    <>
      <CropperContainer>
        <Cropper
          image={file}
          crop={transformations.crop}
          rotation={transformations.rotation}
          zoom={transformations.zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          aspect={aspect}
          cropSize={cropSize}
          setCropSize={setCropSize}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onRotationChange={onRotationChange}
          onZoomChange={onZoomChange}
          onMediaLoaded={onMediaLoaded}
        />
      </CropperContainer>
      <Controls>
        <SliderContainer>
          <Icon icon={IconTypes.ZoomOut} title="Zoom Out" {...IconProps} />
          <StyledSlider
            value={transformations.zoom}
            min={minZoom}
            max={maxZoom}
            step={zoomStep}
            aria-labelledby="Zoom"
            onChange={onZoomChange}
          />
          <Icon icon={IconTypes.ZoomIn} title="Zoom In" {...IconProps} />
        </SliderContainer>
        <SliderContainer>
          <Icon icon={IconTypes.RotateLeft} title="Rotate Left" {...IconProps} />
          <StyledSlider
            value={transformations.rotation}
            min={minRotation}
            max={maxRotation}
            step={rotationStep}
            aria-labelledby="Rotation"
            onChange={onRotationChange}
          />
          <Icon icon={IconTypes.RotateRight} title="Rotate Right" {...IconProps} />
        </SliderContainer>
      </Controls>
    </>
  );
};
