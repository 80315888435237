import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

import { theme } from '../themes/default';
import icons from './icons';

export interface PathProps {
  d?: string;
  opacity?: string | number;
  transform?: string;
  style?: React.CSSProperties;
  className?: string;
  x1?: string;
  x2?: string;
  y1?: string;
  y2?: string;
  cx?: string;
  cy?: string;
  r?: string;
  type?: 'path' | 'circle' | 'line' | 'rect';
  fillRule?: string;
  clipRule?: string;
  stroke?: number;
  strokeColor?: string;
  outline?: boolean;
  width?: number;
  height?: number;
  rx?: number;
  fill?: string;
}

export interface IconConfig {
  width: number;
  height: number;
  paths: Array<PathProps>;
}

export enum IconTypes {
  AlertDots = 'alertDots',
  AlignCenter = 'alignCenter',
  AlignLeft = 'alignLeft',
  AlignRight = 'alignRight',
  AngleDown = 'angleDown',
  AngleLeft = 'angleLeft',
  AngleRight = 'angleRight',
  AngleUp = 'angleUp',
  ArrowRight = 'arrowRight',
  ArrowLeft = 'arrowLeft',
  BarsDuo = 'barsDuo',
  BarsSolid = 'barsSolid',
  Bars = 'bars',
  Bell = 'bell',
  Bold = 'bold',
  Bus = 'bus',
  Portfolio = 'portfolio',
  Check = 'check',
  Buildings = 'buildings',
  Calendar = 'calendar',
  Camera = 'camera',
  Code = 'code',
  Copy = 'copy',
  Crop = 'crop',
  Cross = 'cross',
  CrossFilled = 'crossFilled',
  CrossSearch = 'crossSearch',
  Dashboard = 'dashboard',
  DotsTriangle = 'dotsTriangle',
  Dots = 'dots',
  DotsList = 'dotsList',
  Drops = 'drops',
  Eye = 'eye',
  EmergencyRequest = 'emergencyRequest',
  FileBucket = 'fileBucket',
  FileDownload = 'fileDownload',
  FileUpload = 'fileUpload',
  File = 'file',
  FileAdd = 'fileAdd',
  Files = 'files',
  Folders = 'folders',
  Filter = 'filter',
  Fire = 'fire',
  Gear = 'gear',
  GroupAdd = 'groupAdd',
  Group = 'group',
  Global = 'global',
  H = 'h',
  History = 'history',
  HouseBroken = 'houseBroken',
  Image = 'image',
  Info = 'info',
  Quote = 'quote',
  LabelFilled = 'labelFilled',
  Label = 'label',
  Letter = 'letter',
  LinkAngle = 'linkAngle',
  Link = 'link',
  Italic = 'italic',
  List = 'list',
  Grid = 'grid',
  NotificationAlert = 'notificationAlert',
  NotificationCheck = 'notificationCheck',
  NumericList = 'numericList',
  Pdf = 'pdf',
  Pen = 'pen',
  Phone = 'phone',
  Pin = 'pin',
  Property = 'property',
  Play = 'play',
  Plus = 'plus',
  LPlus = 'lplus',
  RectangleBig = 'rectangleBig',
  RectangleMiddle = 'rectangleMiddle',
  RectangleSmall = 'rectangleSmall',
  Revert = 'revert',
  RevertLeft = 'revertLeft',
  RevertRight = 'revertRight',
  Questionmark = 'questionmark',
  RotateLeft = 'rotateLeft',
  RotateRight = 'rotateRight',
  Search = 'search',
  TextAlignLeft = 'textAlignLeft',
  TextAlignRight = 'textAlignRight',
  TextAlignCenter = 'textAlignCenter',
  TextAlignJustify = 'textAlignJustify',
  Time = 'time',
  TimeFilled = 'timeFilled',
  Toxic = 'toxic',
  Trash = 'trash',
  User = 'user',
  Underline = 'underline',
  Video = 'video',
  Virus = 'virus',
  ZoomIn = 'zoomIn',
  ZoomOut = 'zoomOut',
  HeadingOne = 'headingOne',
  HeadingTwo = 'headingTwo',
  DefaultFile = 'defaultFile',
  Shield = 'shield',
}

export interface IconProps extends CustomIconComponentProps {
  icon: IconTypes | string;
  title?: string;
  fillColor?: string;
}

export default function Icon(props: Partial<IconProps>) {
  const { icon, title, width, height, fillColor = theme.primary, className, style } = props;
  const iconConfig: IconConfig = icons[icon as IconTypes];
  const widthAttr = width || iconConfig.width;
  const heightAttr = height || iconConfig.height;
  const viewBox = `0 0 ${iconConfig.width} ${iconConfig.height}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={widthAttr}
      height={heightAttr}
      viewBox={viewBox}
      className={className}
      style={style}
    >
      <title>{title || icon}</title>
      {iconConfig.paths.map((path, index) => {
        const modifiedPath = { ...path };
        let additionalRules = {};

        modifiedPath.style = {
          ...path.style,
          fill: fillColor,
        };

        if (path.outline) {
          modifiedPath.style = {
            ...modifiedPath.style,
            stroke: fillColor,
            strokeWidth: path.stroke || 1,
            fill: 'none',
          };
        }

        if (path.fillRule) {
          additionalRules = {
            ...additionalRules,
            fillRule: path.fillRule,
          };
        }
        if (path.strokeColor) {
          additionalRules = {
            ...additionalRules,
            stroke: path.strokeColor,
          };
        }
        if (path.clipRule) {
          additionalRules = {
            ...additionalRules,
            clipRule: path.clipRule,
          };
        }

        switch (modifiedPath.type) {
          case 'circle':
            return (
              <circle
                cx={modifiedPath.cx}
                cy={modifiedPath.cy}
                r={modifiedPath.r}
                style={modifiedPath.style}
                transform={modifiedPath.transform}
                key={index}
              />
            );
          case 'line':
            return (
              <line
                x1={modifiedPath.x1}
                x2={modifiedPath.x2}
                y1={modifiedPath.y1}
                y2={modifiedPath.y2}
                style={modifiedPath.style}
                transform={modifiedPath.transform}
                key={index}
              />
            );
          case 'rect':
            return (
              <rect width={modifiedPath.width} height={modifiedPath.height} rx={modifiedPath.rx} fill={path.fill} />
            );
          default:
            return (
              <path
                key={index}
                style={modifiedPath.style}
                d={modifiedPath.d}
                opacity={modifiedPath.opacity}
                transform={modifiedPath.transform}
                {...additionalRules}
              />
            );
        }
      })}
    </svg>
  );
}
