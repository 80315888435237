import styled from 'styled-components';

export const ListHeader = styled.div<{ isSmallScreen: boolean; $high?: boolean }>`
  ${({ isSmallScreen }) => (isSmallScreen ? '' : 'display: flex;')};
  width: 100%;
  justify-content: space-between;
  height: ${({ isSmallScreen, $high }) => (!$high ? 'auto' : isSmallScreen ? '60px' : '96px')};
  align-items: center;
  margin-bottom: ${({ $high }) => ($high ? 0 : '24px')};
`;

export const ListActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const ListIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
`;

export const GridCardContainer = styled.div<{ isExtraSmallScreen?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ isExtraSmallScreen }) => (isExtraSmallScreen ? '11px' : '24px')};
`;
