import { OnOpenDrawer } from '../components/Attachment/types';

export enum SCREEN_STATUS {
  extraSmall = 'extraSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

// TODO: move to DatCore
export enum ParentEntities {
  COMPANY = 'company',
  PROPERTY = 'property',
  BUILDING = 'building',
  TENANT_SPACE = 'tenantSpace',
}

export interface BaseListProps {
  onOpenDrawer?: OnOpenDrawer;
  onRefetchParent?: () => void;
}
