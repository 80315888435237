import { Form as AntForm } from 'antd';
import { FormItemProps } from 'antd/es/form/FormItem';
import styled from 'styled-components';

import { useGetScreenStatus } from '../../../hooks';
import { SCREEN_STATUS } from '../../../types';
import { theme } from '../themes/default';

const { Item } = AntForm;

export const StyledItem = styled(Item)<{ isSmallScreen?: boolean }>`
  .ant-form-item-label > label:after {
    content: '';
  }
  .ant-form-item-label {
    display: flex;
    align-items: center;
    text-align: left;
    width: 30%;
    white-space: normal;
    word-wrap: break-word;
  }
  &.ant-form-item {
    margin-bottom: ${({ isSmallScreen }) => (isSmallScreen ? '8px' : '16px')};
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    position: absolute;
    right: ${({ isSmallScreen }) => (isSmallScreen ? '-12px' : '0')};
    bottom: 7px;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
    color: ${theme.alertRed};
  }
  .ant-form-item-explain {
    position: absolute;
  }
  .ant-form-item-label > label {
    height: auto;
    font-size: ${({ isSmallScreen }) => (isSmallScreen ? '12px;' : '')};
  }
`;

export function FormItem(props: FormItemProps) {
  const currentScreen = useGetScreenStatus();

  return <StyledItem {...props} isSmallScreen={currentScreen === SCREEN_STATUS.extraSmall} />;
}
