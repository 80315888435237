import { useEffect, useState } from 'react';

import { ONBOARDING_ENTITIES } from '../components/Onboarding/types';
import { LocalStorage } from '../utils/LocalStorage';

export const useOnboarding = (entity: ONBOARDING_ENTITIES) => {
  const [onboardingStatus, setOnboardingStatus] = useState(true);

  useEffect(() => {
    setOnboardingStatus(Boolean(LocalStorage.getItem(`onboardingStatus-${entity}`)));
  }, [entity]);

  const skipOnboarding = () => {
    setOnboardingStatus(true);
    LocalStorage.setItem(`onboardingStatus-${entity}`, true);
  };

  return { skipOnboardingStatus: onboardingStatus, skipOnboarding };
};
