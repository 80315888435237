/* eslint-disable @typescript-eslint/no-empty-function */
import Gleap from 'gleap';
import { useCallback, useEffect } from 'react';

interface CustomerData {
  name?: string;
  email?: string;
  phone?: string;
  value?: number;
  companyId?: string;
  companyName?: string;
  plan?: string;
  customData?: object;
  createdAt?: Date;
}
type Environment = 'dev' | 'staging' | 'prod';

const ENVIRONMENTS: Record<string, Environment> = {
  development: 'dev',
  staging: 'staging',
  production: 'prod',
  test: 'dev',
};

const GLEAP_SDK_KEY = process.env.REACT_APP_GLEAP_SDK_KEY;

export const useGleap = () => {
  const identifyGleapUser = useCallback(
    (userId: string, customerData: CustomerData) => Gleap.identify(userId, customerData),
    []
  );
  const clearGleapUserIdentity = useCallback(() => Gleap.clearIdentity(), []);

  const initializeGleap = () => {
    if (GLEAP_SDK_KEY) {
      const environmentName = ENVIRONMENTS[process.env.NODE_ENV || 'development'];

      Gleap.initialize(GLEAP_SDK_KEY);
      Gleap.setEnvironment(environmentName);
    }
  };

  useEffect(initializeGleap, []);

  if (!GLEAP_SDK_KEY) {
    return {
      identifyGleapUser: () => {},
      clearGleapUserIdentity: () => {},
    };
  }

  return { identifyGleapUser, clearGleapUserIdentity };
};
