/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react';
import { createEditor } from 'slate';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';

import { Content, SaveContentItemParams, useGetScreenStatus } from '../../../hooks';
import { SCREEN_STATUS } from '../../../types';
import { ContentItemEntityName } from '../ContentItemDrawer';
import Icon from '../Icon/Icon';
import { theme } from '../themes/default';
import { StyledButton, StyledEdit, Toolbar } from './TextSection.styles';
import {
  blockButtonsConfig,
  Element,
  isBlockActive,
  isMarkActive,
  Leaf,
  markButtonsConfig,
  TEXT_ALIGN_TYPES,
  toggleBlock,
  toggleMark,
} from './utils';

interface Props {
  initialValue: any[];
  id?: string;
  createContent?: (arg0: SaveContentItemParams) => void;
  readonly?: boolean;
  showToolbar?: boolean;
  entityName?: ContentItemEntityName;
}

export enum TextSectionType {
  heading = 'heading-one',
  paragraph = 'paragraph',
}

export function TextSection({
  initialValue,
  id,
  createContent,
  readonly,
  showToolbar = true,
  entityName = ContentItemEntityName.Procedure,
}: Props) {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
  const [editable, setEditable] = useState(false);
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;

  markButtonsConfig.map((item) => isMarkActive(editor, item.format));

  const getTitilePlaceholder = () =>
    entityName === ContentItemEntityName.Faq ? 'Type the question' : `Type title of the ${entityName}`;

  const getTextPlaceholder = () =>
    entityName === ContentItemEntityName.Faq ? 'Start to type an answer' : `Start create a new ${entityName}`;

  return (
    <Slate
      editor={editor}
      value={initialValue}
      onChange={(value) => createContent?.({ content: value as Content[], id })}
    >
      {showToolbar && editable && !readonly && (
        <Toolbar isSmallScreen={isSmallScreen}>
          {markButtonsConfig.map((item) => (
            <StyledButton
              key={item.format}
              onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, item.format);
              }}
            >
              <Icon icon={item.icon} fillColor={isMarkActive(editor, item.format) ? theme.black : theme.darkGrey} />
            </StyledButton>
          ))}
          {blockButtonsConfig.map((item) => (
            <StyledButton
              key={item.format}
              onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, item.format);
              }}
            >
              <Icon
                icon={item.icon}
                fillColor={
                  isBlockActive(editor, item.format, TEXT_ALIGN_TYPES.includes(item.format) ? 'align' : 'type')
                    ? theme.black
                    : theme.darkGrey
                }
              />
            </StyledButton>
          ))}
        </Toolbar>
      )}

      <StyledEdit isSmallScreen={isSmallScreen}>
        <Editable
          readOnly={!editable || readonly}
          spellCheck
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onClick={() => setEditable(true)}
          onBlur={() => setEditable(false)}
          tabIndex={0}
          placeholder={
            initialValue[0]?.type === TextSectionType.heading ? getTitilePlaceholder() : getTextPlaceholder()
          }
        />
      </StyledEdit>
    </Slate>
  );
}
