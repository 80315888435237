import { UserRoles } from '@securecore-new-application/securecore-datacore/lib/types';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useCurrentUser, useGetToken } from '../../hooks';
import { useGleap } from '../../hooks/useGleap';
import { ErrorPage, ErrorPageTypes } from '../Errors';

interface Props {
  children: JSX.Element;
  access: UserRoles[];
}

export function PrivateRoute({ access, children }: Props) {
  const { token } = useGetToken();
  const currentUser = useCurrentUser();
  const { identifyGleapUser } = useGleap();

  useEffect(() => {
    const { firstName = '' } = currentUser?.profile ?? {};

    const customerData = {
      name: firstName,
    };

    identifyGleapUser(`u${currentUser?.id}`, customerData);
  }, [currentUser, identifyGleapUser]);

  if (!token) {
    return <Navigate to="/auth" replace />;
  }

  if (!access.find((item) => item === currentUser?.role)) {
    return <ErrorPage type={ErrorPageTypes.unknown} />;
  }

  return children;
}
