import Highlight from 'highlight-react/dist/highlight';
import React from 'react';
import styled from 'styled-components';

import { theme } from '../themes/default';

interface Props {
  children: React.ReactNode;
  search?: string;
}

const StyledSearchHighlight = styled.span`
  mark {
    background-color: ${theme.primary};
    padding: 0;
    color: ${theme.white};
    line-height: 1;
    border-radius: 2px;
  }
`;

export const SearchHighlight: React.FC<Props> = ({ children, search }) => {
  return (
    <StyledSearchHighlight>
      <Highlight search={search}>{children}</Highlight>
    </StyledSearchHighlight>
  );
};
