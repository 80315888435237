import styled from 'styled-components';

import { theme } from '../ui/themes/default';

export const StyledLink = styled.a`
  color: ${theme.primary};
  font-size: 12px;
`;

export const CurrentPasswordWrapper = styled.div`
  margin-bottom: 32px;
`;

export const ForgotLinkWrapper = styled.div`
  margin-left: 30%;
  margin-top: -16px;
`;
