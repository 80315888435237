import { Typography as AntdTypography } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { theme } from '../themes/default';
import { TextColor, TextVariant, TitleVariant } from './Typography';

const { Title, Text } = AntdTypography;

const fontWeightConfig = {
  bodySmall: 400,
  bodyRegular: 400,
  bodySemibold: 600,
  bodyBold: 700,
  bodyMedium: 500,
  description: 500,
};

const textFontSizeConfig = {
  bodySmall: '12px',
  bodyRegular: '15px',
  bodyBold: '15px',
  bodySemibold: '15px',
  bodyMedium: '15px',
  description: '12px',
};

const textColorConfig = {
  primary: theme.mainBlack,
  secondary: theme.darkGrey,
};

const titleFontSizeConfig = {
  heading1: '48px',
  heading2: '36px',
  heading3: '24px',
  heading4: '32px',
};

export interface TextProps {
  variant?: TextVariant;
  upperCase?: boolean;
  color?: TextColor;
  bold?: boolean;

  children: ReactNode;
}

export interface TitleProps {
  variant?: TitleVariant;
  children: ReactNode;
  color?: TextColor;
}

export const StyledText = styled(Text)<TextProps>`
  ${({ upperCase }) => upperCase && { textTransform: 'uppercase' }};
  font-weight: ${({ variant }) => fontWeightConfig[variant || 'bodyRegular']};
  font-size: ${({ variant }) => textFontSizeConfig[variant || 'bodyRegular']};
  color: ${({ color }) => textColorConfig[color || 'primary']};
  ${({ bold }) => bold && { fontWeight: 'bold' }};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled(Title)<TitleProps>`
  font-weight: ${({ variant }) => (variant === 'heading3' ? '600' : 700)} !important;
  font-size: ${({ variant }) => titleFontSizeConfig[variant || 'heading1']} !important;
  color: ${({ color }) => textColorConfig[color || 'primary']} !important;
`;
