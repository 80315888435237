import { DataCoreClient } from '@securecore-new-application/securecore-datacore';

import { LocalStorage } from './LocalStorage';

export const dataCoreClient = new DataCoreClient({
  apolloDefaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    },
  },
  apiUrl: process.env.REACT_APP_API_URL || '',
  getToken: () => LocalStorage.getItem('jwt') as string,
});

export const refetchAllQueries = async () => {
  dataCoreClient.httpClient.queryDeduplication = false;
  await dataCoreClient.httpClient.reFetchObservableQueries();
  dataCoreClient.httpClient.queryDeduplication = true;
};
