import { Checkbox as AntdCheck, Input as AntdInput, InputRef, Radio as AntdRadio } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { InputProps, PasswordProps, TextAreaProps } from 'antd/lib/input';
import { RadioProps } from 'antd/lib/radio';
import { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

import { theme } from '../themes/default';

const { TextArea: AntdTextArea } = AntdInput;
const { Password: AntdPassword } = AntdInput;

export const InputGroup = AntdInput.Group;

export const SHORT_INPUT_STYLE = { maxWidth: '70%' };

const StyledInput = styled(AntdInput)`
  & .ant-input-prefix,
  & .ant-input-suffix {
    & .anticon {
      color: ${theme.lighterGray};
    }
  }
  & .ant-input-suffix .ant-input-clear-icon {
    font-size: 0;
  }
  &.ant-input,
  & .ant-input,
  &.ant-input-affix-wrapper {
    border-radius: ${theme.inputBorderRadius};
  }

  &.ant-input,
  & .ant-input {
    &:focus {
      border-color: ${theme.borderFocus};
      box-shadow: 0 0 0 1px ${theme.borderFocus};
    }
    &:active {
      border-color: ${theme.orange};
    }
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused:hover {
    border-color: ${theme.borderFocus};
  }
  &.ant-input-affix-wrapper-focused {
    border-color: ${theme.borderFocus};
    box-shadow: 0 0 0 1px ${theme.borderFocus};
  }
  & .ant-input-group-addon {
    line-height: 19px;
    font-size: 16px;
    color: ${theme.secondary};
    background: ${theme.white};
  }
  .ant-input-group-addon {
    border-radius: ${theme.inputBorderRadius};

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .ant-input-prefix {
    margin-right: 10px;
  }
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${theme.alertRed};
  }
`;

const StyledTextArea = styled(AntdTextArea)`
  &.ant-input {
    border-radius: ${theme.inputBorderRadius};
    padding: 8px 16px;

    &:focus {
      border-color: ${theme.borderFocus};
      box-shadow: 0 0 0 1px ${theme.borderFocus};
    }
    &:active {
      border-color: ${theme.orange};
    }
  }
`;

const StyledPassword = styled(AntdPassword)`
  &.ant-input-affix-wrapper {
    border-radius: ${theme.inputBorderRadius};

    &:focus {
      border-color: ${theme.borderFocus};
      box-shadow: 0 0 0 1px ${theme.borderFocus};
    }
  }
  &.ant-input-affix-wrapper-focused {
    border-color: ${theme.borderFocus};
    box-shadow: 0 0 0 1px ${theme.borderFocus};
  }
  &.ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${theme.borderFocus};
  }
`;

const StyledRadio = styled(AntdRadio)`
  &.ant-radio-wrapper {
    height: 48px;
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
    color: ${theme.secondary};
    border-radius: ${theme.borderRadioBase};
    transition: ${theme.transitionBG};
    color: ${theme.black};

    span.ant-radio + * {
      padding: 0 0 0 8px;
    }

    .ant-radio::selection {
      background: rgba(243, 109, 50, 0.2);
    }

    &.ant-radio-wrapper-checked {
      background: ${theme.lightOrange};
    }

    &:hover .ant-radio-inner {
      border-color: ${theme.orange};
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 2px rgba(243, 109, 50, 0.2);
    }
  }
`;

const StyledCheck = styled(AntdCheck)`
  &.ant-checkbox-wrapper {
    color: ${theme.black};
  }
`;

interface StyledInputProps extends InputProps {
  innerRef?: (inputElement: HTMLElement) => void;
}

export const Input = forwardRef((props: StyledInputProps, ref: ForwardedRef<InputRef>) => (
  <StyledInput {...props} ref={ref} />
));

export function TextArea(props: TextAreaProps) {
  return <StyledTextArea {...props} />;
}

export function Password(props: PasswordProps) {
  return <StyledPassword {...props} />;
}

export function Radio(props: RadioProps) {
  return <StyledRadio {...props} />;
}

export const RadioGroup = AntdRadio.Group;

export function Checkbox(props: CheckboxProps) {
  return <StyledCheck {...props} />;
}

export default Input;
