import { DataCoreClientError, HttpStatuses } from '@securecore-new-application/securecore-datacore/lib/client';
import { notification } from 'antd';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { isBuildingPage, isCompanyPage, isPropertyPage, isTenantSpacePage } from '../../../routes';
import { dataCoreClient } from '../../../utils/dataCoreClient';
import { ErrorLogger } from '../../../utils/ErrorLogger';
import { logOut } from '../../../utils/logOut';
import { ErrorPage, ErrorPageTypes } from '../ErrorPage';

interface Props {
  children: ReactNode;
}

export const ErrorsWrapper: FC<Props> = ({ children }): JSX.Element => {
  const location = useLocation();
  const [clientError, setError] = useState<DataCoreClientError | null>(null);

  useEffect(() => {
    dataCoreClient.onError = (error) => {
      if (error.type === 'unauthorized') {
        return logOut();
      }

      ErrorLogger.logError(error);

      const message = error.readableMessage || error.message;

      if (error.statusCode !== HttpStatuses.NotFound && message) {
        notification.error({
          message,
        });
      }
      setError(error);
    };
  }, []);

  useEffect(() => {
    setError(null);
  }, [location]);

  const showErrorPage: boolean = useMemo(
    () =>
      isCompanyPage(location.pathname) ||
      isPropertyPage(location.pathname) ||
      isTenantSpacePage(location.pathname) ||
      isBuildingPage(location.pathname),
    [location]
  );

  const renderContent = useCallback(() => {
    if (!clientError || !showErrorPage) {
      return React.Children.only(children);
    }

    if (!clientError.statusCode) {
      return <ErrorPage type={ErrorPageTypes.unknown} />;
    }

    switch (clientError.statusCode) {
      case HttpStatuses.NotFound: {
        return <ErrorPage type={ErrorPageTypes.deleted} />;
      }
      case HttpStatuses.Forbidden: {
        return <ErrorPage type={ErrorPageTypes.forbidden} />;
      }
      case HttpStatuses.ServerError: {
        return <ErrorPage type={ErrorPageTypes.server} />;
      }
      default: {
        return React.Children.only(children);
      }
    }
  }, [children, clientError, showErrorPage]);

  return renderContent() as JSX.Element;
};
