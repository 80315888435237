import { AttachmentTypes } from '@securecore-new-application/securecore-datacore/lib/types';
import { Tooltip } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import type { Identifier, XYCoord } from 'dnd-core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { useGetScreenStatus } from '../../../hooks';
import { Children, ContentType, SaveContentItemParams } from '../../../hooks/useContentItem';
import { NotificationMessages } from '../../../notification';
import { SCREEN_STATUS } from '../../../types';
import { IconButton, InfoButton } from '../Button';
import { ContentItemEntityName } from '../ContentItemDrawer/ContentItemDrawer';
import { Dropdown } from '../Dropdown';
import { Gallery } from '../Gallery';
import Icon, { IconTypes } from '../Icon/Icon';
import { TextSection } from '../TextSection';
import { theme } from '../themes/default';
import { ContentItemIconProps, DropdownItem, OpenUploadParams } from './ContentItem';
import {
  ContentItemEntityContainer,
  ContentItemEntityItem,
  MediaItem,
  MediaRow,
  MediaWrapper,
  PlayIcon,
  VideoPlaceholder,
} from './ContentItem.styles';

interface Props {
  id: string;
  index: number;
  contentItem: ContentType;
  handleSetContent: (arg0: SaveContentItemParams) => void;
  handleDeleteContentItem: (id: string) => void;
  handleMoveContentItem: (dragIndex: number, hoverIndex: number) => void;
  readonly?: boolean;
  entityName?: ContentItemEntityName;
  openUpload(arg0: OpenUploadParams): void;
}

export enum ItemTypes {
  CONTENT_ITEM = 'content_item',
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function ContentItemEntity({
  id,
  index,
  contentItem,
  handleSetContent,
  handleDeleteContentItem,
  handleMoveContentItem,
  openUpload,
  readonly,
  entityName,
}: Props) {
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [videoGalleryOpen, setVideoGalleryOpen] = useState(false);
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CONTENT_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      handleMoveContentItem(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CONTENT_ITEM,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  useEffect(() => {
    drag(dragRef);
    drop(preview(previewRef));
  }, [drag, drop, preview, readonly]);

  const getDropdownItems = useCallback(
    (childInd: number): DropdownItem[] => {
      const items = [
        {
          key: 'image',
          label: (
            <>
              <Icon icon={IconTypes.Image} {...ContentItemIconProps} />
              <div>Image</div>
            </>
          ),
          action: () => openUpload({ type: AttachmentTypes.Image, id, additionalItem: true, childInd: childInd + 1 }),
        },
        {
          key: 'video',
          label: (
            <>
              <Icon icon={IconTypes.Camera} {...ContentItemIconProps} />
              <div>Video</div>
            </>
          ),
          action: () => openUpload({ type: AttachmentTypes.Video, id, additionalItem: true, childInd: childInd + 1 }),
        },
      ];

      return items;
    },
    [id, openUpload]
  );

  const openImageGallery = useCallback(() => {
    setImageGalleryOpen(true);
  }, []);
  const openVideoGallery = useCallback((url: string) => {
    setVideoGalleryOpen(true);
    setVideoURL(url);
  }, []);

  const renderAddButton = useCallback(
    (indx: number) => {
      const mediaLength = contentItem.content[0].children.length;

      if (mediaLength < 6) {
        return (
          <Dropdown dropdownItems={getDropdownItems(indx)} itemsLayout="horizontal">
            <div style={{ cursor: 'pointer' }}>
              <InfoButton leftIcon={<Icon icon={IconTypes.LPlus} fillColor={theme.darkGrey} />} dashed />
            </div>
          </Dropdown>
        );
      }

      return (
        <Tooltip title={NotificationMessages.MEDIA_LIMIT}>
          <span className="ant-dropdown-trigger">
            <InfoButton leftIcon={<Icon icon={IconTypes.LPlus} fillColor={theme.darkGrey} />} dashed disabled />
          </span>
        </Tooltip>
      );
    },
    [contentItem.content, getDropdownItems]
  );

  const renderMedia = useCallback(
    (items: Children[], k: number) => (
      <MediaRow isSmallScreen={isSmallScreen}>
        {items.map((item, indx) => {
          switch (item.type) {
            case 'image':
              return (
                <MediaWrapper isSmallScreen={isSmallScreen}>
                  {!readonly && renderAddButton(k + indx)}
                  <MediaItem onClick={openImageGallery}>
                    <img src={item.url} alt="" />
                  </MediaItem>
                </MediaWrapper>
              );
            default:
              return (
                <MediaWrapper isSmallScreen={isSmallScreen}>
                  {!readonly && renderAddButton(k + indx)}
                  <VideoPlaceholder onClick={() => openVideoGallery(item.url as string)} />
                  <PlayIcon />
                </MediaWrapper>
              );
          }
        })}
      </MediaRow>
    ),
    [isSmallScreen, openImageGallery, openVideoGallery, readonly, renderAddButton]
  );

  const renderItem = useCallback(() => {
    const buffer = [];

    switch (contentItem.type) {
      case 'media':
        buffer.push(renderMedia(contentItem.content[0].children.slice(0, 3), 0));

        if (contentItem.content[0].children.length > 3) {
          buffer.push(renderMedia(contentItem.content[0].children.slice(3, contentItem.content[0].children.length), 3));
        }

        return buffer;
      default:
        return (
          <TextSection
            key={id}
            id={id}
            initialValue={contentItem.content}
            createContent={handleSetContent}
            readonly={readonly}
            entityName={entityName}
          />
        );
    }
  }, [contentItem.content, contentItem.type, entityName, handleSetContent, id, readonly, renderMedia]);

  return (
    <>
      <ContentItemEntityContainer ref={previewRef} style={{ opacity }}>
        {!readonly && (
          <div ref={dragRef} data-handler-id={handlerId}>
            <IconButton icon={<Icon icon={IconTypes.BarsDuo} fillColor={theme.darkGrey} />} />
          </div>
        )}

        <ContentItemEntityItem readonly={readonly}>
          {renderItem()}

          {!readonly && (
            <Popconfirm
              title={
                contentItem.type === 'media'
                  ? NotificationMessages.MEDIA_DELETE_CONFIRM
                  : NotificationMessages.CONTENT_DELETE_CONFIRM
              }
              onConfirm={() => handleDeleteContentItem(id)}
              okText="Yes"
              cancelText="No"
            >
              <IconButton icon={<Icon icon={IconTypes.Cross} fillColor={theme.secondary} />} />
            </Popconfirm>
          )}
        </ContentItemEntityItem>
      </ContentItemEntityContainer>
      {imageGalleryOpen && (
        <Gallery
          key={`gallery-${Date.now()}`}
          closeGallery={() => setImageGalleryOpen(false)}
          items={contentItem.content[0].children
            .filter((child) => child.type === 'image')
            .map((media) => ({
              original: media.url as string,
            }))}
        />
      )}
      {videoGalleryOpen && (
        <Gallery
          key={`gallery-${Date.now()}`}
          closeGallery={() => setVideoGalleryOpen(false)}
          type="video"
          url={videoURL as string}
        />
      )}
    </>
  );
}
