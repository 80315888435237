import { getHtmlToSlateSerializer } from '@securecore-new-application/securecore-parser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { createEditor } from 'slate';
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';

import { useGetScreenStatus } from '../../../hooks';
import { SCREEN_STATUS } from '../../../types';
import { parseSlateToHtml } from '../../../utils/slate';
import Icon from '../Icon/Icon';
import { theme } from '../themes/default';
import { StyledButton, StyledEdit, TextAreaEditorWrapper, TextAreaToolbar } from './TextSection.styles';
import {
  blockButtonsConfig,
  Element,
  isBlockActive,
  isMarkActive,
  Leaf,
  markButtonsConfig,
  TEXT_ALIGN_TYPES,
  toggleBlock,
  toggleMark,
} from './utils';

const deserializeHtmlToSlate = getHtmlToSlateSerializer(DOMParser);

interface TextAreaEditorProps {
  initialValue?: string;
  onChange?: (arg0: unknown) => void;
}

export function TextAreaEditor({ initialValue = undefined, onChange }: TextAreaEditorProps) {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
  const currentScreen = useGetScreenStatus();
  const isSmallScreen = currentScreen === SCREEN_STATUS.extraSmall;
  const [fieldValue, setFieldValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue !== undefined) {
      setFieldValue(initialValue);
    }
  }, [initialValue]);

  markButtonsConfig.map((item) => isMarkActive(editor, item.format));

  if (fieldValue === undefined) {
    return null;
  }

  return (
    <Slate editor={editor} value={deserializeHtmlToSlate(fieldValue) as never} onChange={(value) => onChange?.(value)}>
      <TextAreaToolbar isSmallScreen={isSmallScreen}>
        {markButtonsConfig.map((item) => (
          <StyledButton
            key={item.format}
            onMouseDown={(event) => {
              event.preventDefault();
              toggleMark(editor, item.format);
            }}
          >
            <Icon
              icon={item.icon}
              fillColor={isMarkActive(editor, item.format) ? theme.black : theme.darkGrey}
              width={20}
              height={20}
            />
          </StyledButton>
        ))}
        {blockButtonsConfig.map((item) => (
          <StyledButton
            key={item.format}
            onMouseDown={(event) => {
              event.preventDefault();
              toggleBlock(editor, item.format);
            }}
          >
            <Icon
              icon={item.icon}
              fillColor={
                isBlockActive(editor, item.format, TEXT_ALIGN_TYPES.includes(item.format) ? 'align' : 'type')
                  ? theme.black
                  : theme.darkGrey
              }
              width={20}
              height={20}
            />
          </StyledButton>
        ))}
      </TextAreaToolbar>
      <TextAreaEditorWrapper>
        <StyledEdit isSmallScreen={isSmallScreen}>
          <Editable spellCheck renderElement={renderElement} style={{ minHeight: '100px' }} renderLeaf={renderLeaf} />
        </StyledEdit>
      </TextAreaEditorWrapper>
    </Slate>
  );
}

export function renderTextEditorController<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ field }: { field: ControllerRenderProps<TFieldValues, TFieldName> }): React.ReactElement {
  if (typeof field.value === undefined) {
    return <div />;
  }

  return (
    <TextAreaEditor
      initialValue={field.value}
      {...field}
      onChange={(data) => {
        field.onChange(parseSlateToHtml(data));
      }}
    />
  );
}
