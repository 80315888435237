import { isAndroid } from 'react-device-detect';

import logo from '../../assets/images/logo-small.svg';
import {
  MobileBannerCopy,
  MobileBannerIcon,
  MobileBannerImage,
  MobileBannerLeft,
  MobileBannerLink,
  MobileBannerRight,
  MobileBannerText,
  MobileBannerWrapper,
  MovileBannerTitle,
} from './styles';

export function MobileBanner() {
  const getLink = () => {
    if (isAndroid) {
      return process.env.REACT_APP_ANDROID_APP_LINK;
    }

    return process.env.REACT_APP_IOS_APP_LINK;
  };

  return (
    <MobileBannerWrapper>
      <MobileBannerLeft>
        <MobileBannerIcon>
          <MobileBannerImage src={logo} alt="SecureCore mobile application logo" />
        </MobileBannerIcon>
        <MobileBannerCopy>
          <MovileBannerTitle>SecureCore App</MovileBannerTitle>
          <MobileBannerText>Install the mobile app for a better mobile experience</MobileBannerText>
        </MobileBannerCopy>
      </MobileBannerLeft>
      {isAndroid && (
        <MobileBannerRight>
          <MobileBannerLink href={getLink()}>Download</MobileBannerLink>
        </MobileBannerRight>
      )}
    </MobileBannerWrapper>
  );
}
